<div class="custom-text-field">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <app-text-field-label
          class="d-block"
          [fieldId]="fieldId"
          [label]="label"
          [hintText]="hintText"
          [isHintShown]="isHintShown"
          [control]="control">
        </app-text-field-label>
        <ng-content select="[right-side]"></ng-content>
    </div>
    <ng-content></ng-content>
</div>
