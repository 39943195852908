import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { modalInitialState, ModalState } from '@shared/modules/event-bus/state/modal/modal.state';

@Injectable({
  providedIn: 'root',
})
export class ModalStateService extends EventBusService<ModalState> {
  constructor() {
    super(modalInitialState);
  }

  resetModalStateOnDestroy(): void {
    this.setState({ ...modalInitialState });
  }
}
