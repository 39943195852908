import { Component, OnInit } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/button/button-base.components';

@Component({
  selector: 'app-back-arrow-button',
  templateUrl: './back-arrow-button.component.html',
  styleUrls: ['./back-arrow-button.component.scss'],
})
export class BackArrowButtonComponent extends ButtonBaseComponent implements OnInit {
  ngOnInit(skip?: boolean) {
    return super.ngOnInit(skip);
  }
}
