import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

@Directive({
  selector: '[appInjectFilter]',
})
export class InjectFilterDirective implements OnDestroy {
  @Input()
  set appInjectFilter(filterComponent: ComponentType<any>) {
    this.injectFilterComponent(filterComponent);
  }
  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnDestroy(): void {
    this.clearView();
  }

  private injectFilterComponent(filterComponent: ComponentType<any>): void {
    this.clearView();
    if (filterComponent) {
      const component = this.componentFactoryResolver.resolveComponentFactory(filterComponent);
      this.viewContainer.createComponent(component);
    }
  }

  private clearView(): void {
    this.viewContainer.clear();
  }
}
