import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomDirectivesModule } from '@shared/modules/custom-directives/custom-directives.module';
import { SharedModule } from '@shared/shared.module';
import { SwitchModule } from '@shared/modules/switch/switch.module';
import { TableComponent } from './components/table/table.component';
import { SmallImageDatatableCellComponent } from './components/small-image-table-cell/small-image-datatable-cell.component';
import { CustomCellDirective } from './directives/custom-cell.directive';
import { CheckboxTableCellComponent } from './components/checkbox-table-cell/checkbox-table-cell.component';
import { CheckboxTableHeaderComponent } from './components/checkbox-table-header/checkbox-table-header.component';
import { CustomHeaderDirective } from './directives/custom-header.directive';
import { SwitchTableCellComponent } from './components/switch-table-cell/switch-table-cell.component';

@NgModule({
  declarations: [
    TableComponent,
    SmallImageDatatableCellComponent,
    CustomCellDirective,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    CustomHeaderDirective,
    SwitchTableCellComponent,
  ],
  imports: [CommonModule, NgxDatatableModule, CustomDirectivesModule, SharedModule, SwitchModule],
  exports: [
    TableComponent,
    SmallImageDatatableCellComponent,
    CustomCellDirective,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    CustomHeaderDirective,
    SwitchTableCellComponent,
  ],
})
export class TableModule {}
