import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import includes from 'lodash-es/includes';
import { AuthService } from './services/auth.service';
import { AuthGuardParams } from './classes/AuthGuardParams';
import { AuthUtils } from './utils/auth.utils';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (next.data && next.data.authGuardParams) {
        const authGuardParams = next.data.authGuardParams as AuthGuardParams;
        const userRoles = this.auth.getUserRoles();

        if (includes(userRoles, UserRole.SuperAdmin)) {
          resolve(true);
        }

        if (!this.checkAuthGuardParams(authGuardParams)) {
          return resolve(false);
        }

        this.checkForRoles(authGuardParams, userRoles, () => {
          this.redirectToHomeByRole(authGuardParams, state.url);
          return resolve(false);
        });

        this.checkForPermission(authGuardParams, userRoles, () => {
          this.redirectToHomeByRole(authGuardParams, state.url);
          return resolve(false);
        });

        return resolve(true);
      }
      console.error('AuthGuard: Missing authGuardParams!');
      return resolve(false);
    });
  }

  checkForRoles(authGuardParams, userRoles: UserRole[], callback) {
    if (authGuardParams.rolesDisabled) {
      if (AuthUtils.isContainUserRole(authGuardParams.rolesDisabled, userRoles)) {
        callback();
      }
    } else if (authGuardParams.rolesEnabled) {
      if (!AuthUtils.isContainUserRole(authGuardParams.rolesEnabled, userRoles)) {
        callback();
      }
    }
  }

  checkForPermission(authGuardParams, userRoles: UserRole[], callback) {
    if (authGuardParams.permissionDisabled) {
      if (AuthUtils.isContainUserPermission(authGuardParams.permissionDisabled, userRoles)) {
        callback();
      }
    } else if (authGuardParams.permissionEnabled) {
      if (!AuthUtils.isContainUserPermission(authGuardParams.permissionEnabled, userRoles)) {
        callback();
      }
    }
  }

  checkAuthGuardParams(authGuardParams): boolean {
    if (authGuardParams.rolesDisabled && authGuardParams.rolesEnabled) {
      console.error('Cant have rolesDisabled and rolesEnabled too!');
      return false;
    }
    if (authGuardParams.permissionDisabled && authGuardParams.permissionEnabled) {
      console.error('Cant have permissionDisabled and permissionEnabled too!');
      return false;
    }
    if (
      (authGuardParams.rolesDisabled || authGuardParams.rolesEnabled) &&
      (authGuardParams.permissionDisabled || authGuardParams.permissionEnabled)
    ) {
      console.error('Cant have role and permission restrictions too!');
      return false;
    }

    return true;
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    console.info('Permission denied to view this page - do redirect');
    this.auth.redirectToHomeByRole(authGuardParams, previousUrl);
  }
}
