import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { User } from '@shared/modules/auth/classes/User';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { AppConfig } from '@config/app.config';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { TranslateInstance } from '@shared/utils/TranslateInstance';

/** Main component */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public isSideFilterOpened: boolean;
  public slideHeader: boolean;

  loggedInUser: User;
  showSideFilter = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private sideFilterService: SideFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.initLoggedInUser();
    this.initTranslationConfig();
    this.listenForNavigationEndChanges();
    this.listenSideFilterToggleChanges();

    return super.ngOnInit();
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  private initTranslationConfig(): void {
    this.translate.addLangs(AppConfig.languages);
    this.translate.setDefaultLang(AppConfig.defaultLanguage);
    this.translate.use(AppConfig.defaultLanguage);
    TranslateInstance.translateService = this.translate;
  }

  listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return child.snapshot;
            } else {
              return null;
            }
          }

          return null;
        }),
        untilDestroyed(this)
      )
      .subscribe((snapshot: any) => {
        this.setTitle(snapshot);
      });
  }

  listenSideFilterToggleChanges(): void {
    this.sideFilterService
      .select('isOpen')
      .pipe(untilDestroyed(this))
      .subscribe((isOpened) => (this.isSideFilterOpened = isOpened));
  }

  setTitle(snapshot): void {
    if (snapshot.data && snapshot.data.title) {
      this.translate
        .get(snapshot.data.title)
        .pipe(first())
        .subscribe((translated) => {
          this.titleService.setTitle(translated);
        });
    }
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
