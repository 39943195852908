import { IEnvironment } from '@environments/classes/IEnvironment';

export const environment: IEnvironment = {
  name: 'TEMP-DEV',
  production: false,
  apiUrl: 'https://whc-api-dev.attrecto-dev.com/api',
  authStorageStrategy: 'cookie',
  cookieDomain: '.attrecto-dev.com',
  backendVersion: 'v1',
  assetUrl: 'https://whc-api-dev.attrecto-dev.com',
};
