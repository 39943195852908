import { Component, ViewEncapsulation } from '@angular/core';
import { MatModalBaseComponent } from '../../../mat-modal/mat-modal-base.component';

@Component({
  selector: 'app-cookie-popup-modal',
  templateUrl: './cookie-popup-modal.component.html',
  styleUrls: ['./cookie-popup-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CookiePopupModalComponent extends MatModalBaseComponent {
  cookieAccepted(): void {
    this.closeModal(true);
  }
}
