<div class="d-flex justify-content-center align-items-center">
    <span class="d-flex align-items-center" *ngIf="isInfoHintShown" inlineSVG="assets/image/info-icon.svg"
      [tippy]="hintText | translate" variation="hint"
      [placement]="placement">
    </span>

    <span class="d-flex align-items-center" *ngIf="isErrorHintShown" inlineSVG="assets/image/alert-icon.svg"
      [tippy]="errors ? errorList : hintText" variation="hint"
      [placement]="placement">
    </span>
</div>

<ng-template #errorList>
    <app-hint-error-list [errors]="errors"></app-hint-error-list>
</ng-template>
