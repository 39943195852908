<div class="not-supported-container">
    <div class="not-supported-img-backdrop">
        <h1 class="thin-title not-supported-title">
            {{'BROWSER_NOT_SUPPORTED.TITLE' | translate}}
        </h1>
        <div class="not-supported-card">
            <div class="not-supported-card-body">
                <div class="not-supported-message"
                     [innerHTML]="'BROWSER_NOT_SUPPORTED.CONTENT' | translate">
                </div>
            </div>
        </div>
        <div class="not-supported-browser-list">
            <div class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_firefox.png'" alt="firefox"/>
            </div>
            <div class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_chrome.svg'" alt="chrome"/>
            </div>
            <div class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_opera.svg'" alt="opera"/>
            </div>
            <div *ngIf="!browsersNotSupported.ie11 && browsersNotSupported.ie10"
                 class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_ie_11.svg'" alt="ie"/>
            </div>
            <div *ngIf="!browsersNotSupported.ie11 && !browsersNotSupported.ie10"
                 class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_ie.svg'" alt="ie"/>
            </div>
            <div *ngIf="!browsersNotSupported.edge"
                 class="not-supported-browser-icon">
                <img [src]="'assets/image/browser/browser_edge.svg'" alt="edge"/>
            </div>
        </div>
    </div>
</div>
