import { AfterViewInit, Component } from '@angular/core';
import { CookiePopupService } from './cookie-popup.service';

@Component({
  selector: 'app-cookie-popup',
  template: '<ng-content></ng-content>',
})
export class CookiePopupComponent implements AfterViewInit {
  constructor(private cookieService: CookiePopupService) {}

  // make modal appears after app view init
  ngAfterViewInit(): void {
    // show popup only if user not selected an option yet
    if (this.cookieService.cookieAccepted === null) {
      this.cookieService.openDialog();
    }
  }
}
