export class AppConfig {
  static loginUrl = '/auth-user';
  static partnersUrl = '/partners';
  static positionsUrl = '/positions';
  static candidatesUrl = '/candidates';

  static homeUrl = AppConfig.partnersUrl;

  static dateFormat = 'YYYY-MM-D';
  static timeFormat = 'hh:mm:ss';
  static backendTimezone = '+00:00'; // TODO: should get this from BE on every app load, or MUST BE SURE on Backend side that timeZone will not change ever

  static browsersNotSupported = {
    ie11: true,
    ie10: true,
    edge: false,
    safari: false,
  };

  static sessionStorage = {
    prevPartnerFilter: 'prevPartnerFilter',
    prevCandidateFilter: 'prevCandidateFilter',
    prevPositionFilter: 'prevPositionFilter',
  };

  static languages: string[] = ['hu', 'en'];
  static defaultLanguage: string = AppConfig.languages[0];
  static headerTitleTranslateMap = {
    [AppConfig.partnersUrl]: 'common.partner',
    [AppConfig.positionsUrl]: 'common.position',
    [AppConfig.candidatesUrl]: 'common.candidate',
  };
}
