import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../base-component/base.component';

@UntilDestroy()
@Component({
  template: '',
})
export class ButtonBaseComponent extends BaseComponent {
  @Input() text = 'Button';
  @Input() disabled: boolean;
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
