import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { BrowserNotSupportedComponent } from './browser-not-supported.component';

@NgModule({
  declarations: [BrowserNotSupportedComponent],
  exports: [BrowserNotSupportedComponent],
  imports: [CommonModule, SharedModule],
})
export class BrowserNotSupportedModule {}
