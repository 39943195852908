import { Observable, Observer } from 'rxjs';

export class BlobToBase64Converter {
  static convert(data: Blob, stripMimeType: boolean): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const result: string = reader.result as string;
        const strippedResult = stripMimeType ? result.split(',')[1] : result;
        observer.next(strippedResult);
        observer.complete();
      };
      reader.onerror = () => {
        observer.error('Error while converting to base64');
        observer.complete();
      };
    });
  }
}
