import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { appInitialState, AppState } from '@shared/modules/event-bus/state/app/app.state';
import { Observable, of } from 'rxjs';
import { Office } from '@pages/partners/classes/Office';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EndpointsConfig } from '@config/endpoints.config';
import { HttpService } from '@shared/modules/http/http.service';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { City } from '@shared/classes/City';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { Country } from '@shared/classes/Country';
import { EducationType } from '@pages/positions/classes/EducationType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';

@Injectable({
  providedIn: 'root',
})
export class AppStateService extends EventBusService<AppState> {
  constructor(private http: HttpService) {
    super(appInitialState);
  }

  getOffices(): Observable<Office[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isOfficesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('offices')
          : this.http
              .get(EndpointsConfig.offices)
              .pipe(tap((offices: Office[]) => this.setState({ isOfficesLoaded: true, offices })));
      })
    );
  }

  getCountries(): Observable<Country[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isCountriesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('countries')
          : this.http
              .get(EndpointsConfig.countries)
              .pipe(
                tap((countries: Country[]) => this.setState({ isCountriesLoaded: true, countries }))
              );
      })
    );
  }

  getSources(): Observable<Source[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isSourcesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('sources')
          : this.http
              .get(EndpointsConfig.sources)
              .pipe(tap((sources: Source[]) => this.setState({ isSourcesLoaded: true, sources })));
      })
    );
  }

  getJobTypes(): Observable<JobType[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isJobTypesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('jobTypes')
          : this.http
              .get(EndpointsConfig.jobTypes)
              .pipe(
                tap((jobTypes: JobType[]) => this.setState({ isJobTypesLoaded: true, jobTypes }))
              );
      })
    );
  }

  getSpecializations(): Observable<Specialization[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isSpecializationsLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('specializations')
          : this.http
              .get(EndpointsConfig.specializations)
              .pipe(
                tap((specializations: Specialization[]) =>
                  this.setState({ isSpecializationsLoaded: true, specializations })
                )
              );
      })
    );
  }

  getLanguages(): Observable<Language[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isLanguagesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('languages')
          : this.http.get(EndpointsConfig.languages).pipe(
              tap((languages: Language[]) =>
                this.setState({
                  isLanguagesLoaded: true,
                  languages,
                })
              )
            );
      })
    );
  }

  getLanguageLevels(): Observable<LanguageLevel[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isLanguageLevelsLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('languageLevels')
          : this.http.get(EndpointsConfig.languageLevels).pipe(
              tap((languageLevels: LanguageLevel[]) =>
                this.setState({
                  isLanguageLevelsLoaded: true,
                  languageLevels,
                })
              )
            );
      })
    );
  }

  getDocumentTypes(): Observable<CandidateDocumentType[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isDocumentTypesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('documentTypes')
          : this.http.get(EndpointsConfig.documentTypes).pipe(
              tap((documentTypes: CandidateDocumentType[]) =>
                this.setState({
                  isDocumentTypesLoaded: true,
                  documentTypes,
                })
              )
            );
      })
    );
  }

  getEmploymentTypes(): Observable<EmploymentType[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isEmploymentTypesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('employmentTypes')
          : this.http.get(EndpointsConfig.employmentTypes).pipe(
              tap((employmentTypes: EmploymentType[]) =>
                this.setState({
                  isEmploymentTypesLoaded: true,
                  employmentTypes,
                })
              )
            );
      })
    );
  }

  getEducationTypes(): Observable<EducationType[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isEducationTypesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('educationTypes')
          : this.http.get(EndpointsConfig.educationTypes).pipe(
              tap((educationTypes: EducationType[]) =>
                this.setState({
                  isEducationTypesLoaded: true,
                  educationTypes,
                })
              )
            );
      })
    );
  }

  getWorkExperiences(): Observable<WorkExperience[]> {
    return of([]).pipe(
      withLatestFrom(this.select('isWorkExperiencesLoaded')),
      switchMap(([_, isLoaded]) => {
        return isLoaded
          ? this.select('workExperiences')
          : this.http.get(EndpointsConfig.workExperiences).pipe(
              tap((workExperiences: WorkExperience[]) =>
                this.setState({
                  isWorkExperiencesLoaded: true,
                  workExperiences,
                })
              )
            );
      })
    );
  }

  getCityByName(countryId: number, cityName: string): Observable<City[]> {
    return this.http.get(EndpointsConfig.citySearch(countryId), { name: cityName });
  }
}
