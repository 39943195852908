import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  SimpleChanges,
} from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';
import { TextFieldComponent } from '@shared/modules/text-field/text-field.component';
import { AbstractControl } from '@angular/forms';
import { PhotoUploadComponent } from '@shared/modules/photo-upload/components/photo-upload/photo-upload.component';
import { SelectComponent } from '@shared/modules/form-components/select/select.component';
import { DatepickerComponent } from '@shared/modules/form-components/datepicker/datepicker.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUploadComponent } from '@shared/modules/form-components/file-upload/file-upload.component';
import { TextAreaComponent } from '@shared/modules/form-components/text-area/text-area.component';

@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements AfterViewInit {
  @ContentChild(TextFieldComponent, { static: true }) textFieldInput: TextFieldComponent;
  @ContentChild(PhotoUploadComponent, { static: true })
  photoUploadComponent: PhotoUploadComponent;
  @ContentChild(SelectComponent, { static: true }) selectComponent: SelectComponent;
  @ContentChild(DatepickerComponent, { static: true }) datePickerComponent: DatepickerComponent;
  @ContentChild(FileUploadComponent, { static: true }) fileUploadComponent: FileUploadComponent;
  @ContentChild(TextAreaComponent, { static: true }) textAreaComponent: TextAreaComponent;

  control: AbstractControl;
  isHintShown = true;
  hintText: string;
  label: string;
  fieldId: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const component = this.getComponent();
    this.setData(component);

    component?.changeSubject$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((data: SimpleChanges) => {
        this.hintText = data.hintText?.currentValue;
      });
  }

  private setData(component: FormInputBaseComponent): void {
    if (component) {
      this.control = component.control;
      this.isHintShown = component.isHintShown;
      this.hintText = component.hintText;
      this.label = component.label;
      this.fieldId = component.textFieldId;
      this.cdr.detectChanges();
    }
  }

  private getComponent(): FormInputBaseComponent {
    const inputTypes = [
      this.textFieldInput,
      this.photoUploadComponent,
      this.selectComponent,
      this.datePickerComponent,
      this.fileUploadComponent,
      this.textAreaComponent,
    ];

    return inputTypes.find((input: FormInputBaseComponent) => !!input?.control) || null;
  }
}
