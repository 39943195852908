import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetBackgroundImage]',
})
export class SetBackgroundImageDirective {
  @Input() set appSetBackgroundImage(imageUrl: string) {
    this.setImage(imageUrl);
  }

  constructor(private element: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  private setImage(imageUrl: string): void {
    if (this.element && imageUrl) {
      this.renderer.setStyle(this.element.nativeElement, 'backgroundImage', `url('${imageUrl}')`);
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'backgroundImage', `none`);
    }
  }
}
