import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';

export default {
  saveButtonClickAction: () => createAction(modalActionTypes.saveButtonClick),
  confirmButtonClickAction: (actionType: string) =>
    createAction(modalActionTypes.confirmButtonClick, actionType),
  dismissButtonClick: (actionType?: string) =>
    createAction(modalActionTypes.dismissButtonClick, actionType),
  closeModalAction: () => createAction(modalActionTypes.closeModalAction),
};
