import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'partners',
    pathMatch: 'full',
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./pages/partners/partners.module').then((mod) => mod.PartnersModule),
  },
  {
    path: 'positions',
    loadChildren: () =>
      import('./pages/positions/positions.module').then((mod) => mod.PositionsModule),
  },
  {
    path: 'candidates',
    loadChildren: () =>
      import('./pages/candidates/candidates.module').then((mod) => mod.CandidatesModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then((mod) => mod.UsersModule),
  },
  {
    path: 'auth-user',
    loadChildren: () =>
      import('./pages/auth-user/auth-user.module').then((mod) => mod.AuthUserModule),
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then((mod) => mod.NotFoundModule),
  },
  {
    path: 'form-fields-demo',
    loadChildren: () =>
      import('./pages/form-fields-demo/form-fields-demo.module').then(
        (mod) => mod.FormFieldsDemoModule
      ),
  },
  {
    path: '**',
    redirectTo: 'notfound',
  },
];

/** Main routing module */
export const AppRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
