import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';
import { CustomCell } from '@shared/modules/table/classes/CustomCell';

@Directive({
  selector: '[appCustomCell]',
})
export class CustomCellDirective implements OnInit {
  columnName: string;
  cellTemplate: TemplateRef<any>;
  component: ComponentType<any>;

  @Input() set appCustomCell({ name, component }: CustomCell<ICustomCellComponent>) {
    this.columnName = name;
    this.component = component;
  }

  constructor(
    private templateRef: TemplateRef<ICustomCellComponent>,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const componentFactory = this.cfr.resolveComponentFactory(this.component);
    const component = this.vcr.createComponent<ICustomCellComponent>(componentFactory);
    this.cellTemplate = component.instance.cellTemplate;
  }
}
