import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SwitchComponent } from './components/switch/switch.component';

@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, UiSwitchModule],
  exports: [SwitchComponent],
})
export class SwitchModule {}
