<form [formGroup]="form" class="documents">
    <app-form-field class="d-block mb-10px">
       <app-file-upload [isHintShown]="true" label="common.file" formControlName="file"></app-file-upload>
    </app-form-field>

    <app-form-field class="d-block mb-10px">
        <app-select
          [options]="types$ | async"
          label="candidates.document_type"
          bindLabel="name"
          appendTo=".modal-frame"
          bindValue="id"
          bindValueByPropertyNameToControl="id"
          formControlName="documentTypeId">
        </app-select>
    </app-form-field>

    <app-form-field *ngIf="isExpiryDateFieldShown">
        <app-datepicker
          [isHintShown]="true"
          label="candidates.expiry_date"
          [minDate]="today"
          formControlName="expiryDate">
        </app-datepicker>
    </app-form-field>
</form>
