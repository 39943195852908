<div class="whc-form-field text-area-field" [class.field-disabled]="control.disabled">
    <textarea
      [id]="textFieldId"
      [placeholder]="!label && placeholder || '' | translate | appFirstLetterUppercase"
      [value]="value"
      [rows]="rows"
      [disabled]="disabled"
      (keyup)="keyUp.emit()"
      (input)="onChange($event.target['value'])"
      (blur)="onTouched()">
    </textarea>
</div>
