import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { Contact } from '@pages/partners/classes/Contact';
import { UserType } from '@pages/users/classes/UserType';
import { EmailValidatorPayload } from '@pages/users/classes/EmailValidatorPayload';
import { EmailValidatorResponse } from '@pages/users/classes/EmailValidatorResponse';
import { EndpointsConfig } from '@config/endpoints.config';
import { pluck } from 'rxjs/operators';
import { User } from '@shared/modules/auth/classes/User';
import { UserAvatarDto } from '@pages/users/classes/UserAvatarDto';
import { AvatarUploadResponse } from '@pages/users/classes/AvatarUploadResponse';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpService) {}

  getUsersByType(type: UserType): Observable<Contact[]> {
    const typeMap = {
      [UserType.Contacts]: 'contacts',
      [UserType.FieldManagers]: 'fieldManagers',
      [UserType.ProjectManager]: 'projectManagers',
    };

    return this.http
      .get(EndpointsConfig.usersListByRole, null, {
        key: 'types',
        value: [type],
      })
      .pipe(pluck(typeMap[type]));
  }

  checkEmailExists(payload: EmailValidatorPayload): Observable<EmailValidatorResponse> {
    return this.http.post(EndpointsConfig.usersCheckEmail, payload);
  }

  getMe(): Observable<User> {
    return this.http.get(EndpointsConfig.me);
  }

  uploadUserAvatar(avatarDto: UserAvatarDto): Observable<AvatarUploadResponse> {
    return this.http.post(EndpointsConfig.userAvatar, avatarDto);
  }
}
