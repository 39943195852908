import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { HintComponent } from './components/hint/hint.component';
import { HintErrorListComponent } from './components/hint-error-list/hint-error-list.component';

@NgModule({
  declarations: [HintComponent, HintErrorListComponent],
  exports: [HintComponent, HintErrorListComponent],
  imports: [CommonModule, InlineSVGModule, TippyModule, TranslateModule, CustomPipesModule],
})
export class HintModule {}
