import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast/lib/hot-toast-ref';
import { CreateHotToastRef } from '@ngneat/hot-toast';

@Component({
  selector: 'app-toast-base',
  templateUrl: './toast-base.component.html',
  styleUrls: ['./toast-base.component.scss'],
})
export class ToastBaseComponent {
  @Input() message: string;
  @Input() iconUrl: string;
  @Input() toastRef: CreateHotToastRef;
  @ViewChild('toastTemplate', { static: true, read: TemplateRef })
  toastTemplate: TemplateRef<HotToastRef>;

  onClose(): void {
    this.toastRef.close({ dismissedByAction: true });
  }
}
