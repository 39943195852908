import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { PartnerFiltersKey } from '@config/filter.config';
import { AppConstants } from '@config/app.constant';
import { CardFilteringBaseComponent } from '@shared/modules/filtering/components/card-filtering-base/card-filtering-base.component';

@UntilDestroy()
@Component({
  selector: 'app-card-filtering',
  templateUrl: './card-filtering.component.html',
  styleUrls: ['./card-filtering.component.scss'],
})
export class CardFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  @Input() savedFilter$: Observable<Object>;

  search = new FormControl();

  constructor(readonly eRef: ElementRef, readonly filteringService: FilteringService) {
    super(eRef, filteringService);
  }

  ngOnInit() {
    if (this.filterKey === PartnerFiltersKey.TaxNumber) {
      this.search.setValidators(Validators.minLength(AppConstants.minInputLength));
    }
    this.listenSearchFieldChange();
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: string | number[] | undefined) => {
        this.setValueForControls(items);
      });
    return super.ngOnInit();
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    if (isChecked) {
      this.filterValueChange.emit({ [this.filterKey]: this.search.value });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  private listenSearchFieldChange(): void {
    this.search.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((value: string) => {
          this.search.patchValue(value, { emitEvent: false });
          if (this.search.valid && !(value === null || value === '')) {
            this.isDisabled = false;
            this.isChecked = true;
            this.filterValueChange.emit({ [this.filterKey]: value });
            this.isOpen = true;
          } else {
            this.isDisabled = true;
            this.isChecked = false;
            this.filterValueChange.emit({ [this.filterKey]: '' });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setValueForControls(valueOfControl: any): void {
    if (typeof valueOfControl !== 'undefined') {
      this.search.setValue(valueOfControl);
    } else {
      this.resetFormControls();
    }
  }

  private resetFormControls(): void {
    this.search.patchValue('');
    this.search.updateValueAndValidity();
  }
}
