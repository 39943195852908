import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/button/button-base.components';

@Component({
  selector: 'app-filter-menu-button',
  templateUrl: './filter-menu-button.component.html',
  styleUrls: ['./filter-menu-button.component.scss'],
})
export class FilterMenuButtonComponent extends ButtonBaseComponent {
  @Input() isOpen: boolean;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
