import { Component, OnInit } from '@angular/core';
import { PositionsService } from '@pages/positions/services/positions.service';
import { Observable } from 'rxjs';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { AppConfig } from '@config/app.config';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-position-detail-header',
  templateUrl: './position-detail-header.component.html',
  styleUrls: ['./position-detail-header.component.scss'],
})
export class PositionDetailHeaderComponent implements OnInit {
  isDetailLoaded$: Observable<boolean>;
  detail: PositionDetail;
  pages = [];
  urls = {
    candidates: 'candidates',
    detail: '',
    advertisements: 'advertisements',
  };

  constructor(
    private positionService: PositionsService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isDetailLoaded$ = this.positionService.select('isDetailLoaded');
    this.positionService
      .select('positionDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        this.detail = detail;
        this.pages = this.getPages(detail?.id);
      });
  }

  onNavigation(): void {
    this.router.navigate([AppConfig.positionsUrl]);
  }

  private getPages(positionId: number) {
    if (!positionId) {
      return [];
    }

    return [
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.candidates],
        text: 'positions.header.candidates',
        isActive: this.location.path().indexOf(this.urls.candidates) > -1,
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId],
        text: 'positions.header.detail',
        isActive: !this.location
          .path()
          .split('/')
          .some((part) => [this.urls.candidates, this.urls.advertisements].indexOf(part) > -1),
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.advertisements],
        text: 'positions.header.advertisements',
        isActive: this.location.path().indexOf(this.urls.advertisements) > -1,
      },
    ];
  }
}
