import { Component, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';

@Component({
  selector: 'app-card-filtering-label',
  templateUrl: './card-filtering-label.component.html',
  styleUrls: ['./card-filtering-label.component.scss'],
})
export class CardFilteringLabelComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
}
