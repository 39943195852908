<div class="whc-form-field" [class.field-disabled]="control.disabled">
    <input
      #input
      [id]="textFieldId"
      [type]="type"
      [placeholder]="!label && placeholder || '' | translate | appFirstLetterUppercase"
      [value]="value"
      [disabled]="disabled"
      (keyup)="keyUp.emit()"
      (input)="onChange($event.target['value'])"
      (blur)="onTouched()"
    />
</div>
