import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICustomHeaderComponent } from '@shared/modules/table/classes/ICustomHeaderComponent';

@Component({
  selector: 'app-checkbox-table-header',
  templateUrl: './checkbox-table-header.component.html',
  styleUrls: ['./checkbox-table-header.component.scss'],
})
export class CheckboxTableHeaderComponent implements ICustomHeaderComponent {
  checkBox = new FormControl();
  @ViewChild(TemplateRef, { static: true }) headerTemplate: TemplateRef<any>;
}
