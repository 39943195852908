import { Component } from '@angular/core';
import { AppConfig } from '@config/app.config';

@Component({
  selector: 'app-browser-not-supported',
  templateUrl: './browser-not-supported.component.html',
  styleUrls: ['./browser-not-supported.component.scss'],
})
export class BrowserNotSupportedComponent {
  browsersNotSupported = AppConfig.browsersNotSupported;
}
