import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { CustomCellDirective } from '@shared/modules/table/directives/custom-cell.directive';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { debounceTime, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CustomHeaderDirective } from '@shared/modules/table/directives/custom-header.directive';
import { AppConstants } from '@config/app.constant';
import { TableService } from '@shared/services/table.service';
import { tableActionTypes } from '@shared/modules/table/actions/table.action-types';

const RECALCULATE_EVENT_DELAY = 300;

@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
  @ContentChildren(CustomCellDirective)
  customCells: QueryList<CustomCellDirective>;
  @ContentChildren(CustomHeaderDirective)
  customHeaders: QueryList<CustomHeaderDirective>;
  @ViewChild(DatatableComponent, { static: true }) dataTable: DatatableComponent;
  @Input() columnConfig: TableColumn[] = [];
  @Input() data: any[] = [];
  @Input() unclickableColumns: string[] = [];
  @Output() rowClick = new EventEmitter<number>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() sortBy = new EventEmitter<any>();
  @Output() selectChange = new EventEmitter<any>();
  columnConfigWithCustomCells: TableColumn[];
  columnMode = ColumnMode;
  SelectionType = SelectionType;
  messages = {
    emptyMessage: this.translate.instant('common.not_found_datatable'),
  };
  selected = [];
  readonly headerHeight = AppConstants.tableHeaderHeight;
  readonly rowHeight = AppConstants.tableRowHeight;

  constructor(
    private cdr: ChangeDetectorRef,
    private sideFilterService: SideFilterService,
    private tableService: TableService,
    private translate: TranslateService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.listenResetSelectedItemsAction();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectChange.emit(this.selected);
  }

  onActivate(event) {
    if (event.type === 'click' && !this.unclickableColumns.includes(event.column.prop)) {
      this.rowClick.emit(event.row.id);
    } else {
      event.event.stopPropagation();
    }
  }

  onScroll(offsetY: number) {
    let offset = offsetY;
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (offsetY === 0) {
      offset = undefined;
    }
    if (offset + viewHeight >= this.data.length * this.rowHeight) {
      this.loadMore.emit();
    }
  }

  onResize() {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (viewHeight >= this.data.length * this.rowHeight) {
      this.loadMore.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.updateTableData();
      this.onResize();
    }
  }

  ngAfterViewInit(): void {
    this.setColumnConfig();
    this.listenEventsToRecalculate();
  }

  private setColumnConfig(): void {
    this.customCells.forEach((customCell) => {
      const foundColumn = this.columnConfig.find(
        (configItem) => configItem.prop === customCell.columnName
      );
      if (foundColumn) {
        foundColumn.cellTemplate = customCell.cellTemplate;
      }
    });
    this.customHeaders.forEach((customHeader) => {
      const foundColumn = this.columnConfig.find(
        (configItem) => configItem.prop === customHeader.headerName
      );
      if (foundColumn) {
        foundColumn.headerTemplate = customHeader.headerTemplate;
      }
    });
    this.columnConfigWithCustomCells = [...this.columnConfig];
    this.cdr.detectChanges();
  }

  private updateTableData(): void {
    if (this.data) {
      this.data = [...this.data];
    }
    this.cdr.detectChanges();
  }

  private listenEventsToRecalculate(): void {
    merge(
      this.dataTable.resize.asObservable(),
      this.sideFilterService.select('isOpen'),
      this.dataTable.select.asObservable()
    )
      .pipe(debounceTime(RECALCULATE_EVENT_DELAY), untilDestroyed(this))
      .subscribe(() => {
        this.dataTable.recalculate();
        this.cdr.detectChanges();
      });
  }

  private listenResetSelectedItemsAction(): void {
    this.tableService.eventBus
      .on(tableActionTypes.resetSelectedItems)
      .pipe(
        tap(() => {
          this.dataTable.selected = [];
          this.dataTable.select.emit({ selected: [] });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
