import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  sideFilterInitialState,
  SideFilterState,
} from '@shared/modules/event-bus/state/side-filter/side-filter.state';

@Injectable({
  providedIn: 'root',
})
export class SideFilterService extends EventBusService<SideFilterState> {
  constructor() {
    super(sideFilterInitialState);
  }

  toggleSideFilter(isOpen?: boolean) {
    this.setState({ isOpen });
  }
}
