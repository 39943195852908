<button
  [ngClass]="{
    'text-center': textAlign === 'center',
   'text-left': textAlign === 'left',
   'text-right': textAlign === 'right',
   'white': isWhite
   }"
  type="button"
  class="close-button"
  (click)="buttonClick.emit()"
  [tippy]="hintText | translate"
  [isEnabled]="!!hintText"
  variation="hint"
  [inlineSVG]="iconUrl">
</button>
