import { Injectable, Injector } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  partnerInitialState,
  PartnerState,
} from '@shared/modules/event-bus/state/partners/partner.state';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { headerActionTypes } from '@shared/modules/header/actions/header.action-types';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mapTo,
  mergeAll,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { merge, Observable, of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { partnerActionTypes } from '@pages/partners/actions/partner.action-types';
import partnerActions from '@pages/partners/actions/partner.actions';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { AppConstants } from '@config/app.constant';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { ProjectDto } from '@pages/partners/classes/ProjectDto';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { PartnerApiService } from '@pages/partners/services/partner-api.service';
import { PartnerDto } from '@pages/partners/classes/PartnerDto';
import { FormGroup } from '@angular/forms';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { ProjectCardTableRow } from '@pages/partners/classes/ProjectCardTableRow';
import { ConfirmModalComponent } from '@shared/modules/mat-modal/components/confirm-modal/confirm-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ListData } from '@shared/classes/ListData';
import { historyActionTypes } from '@shared/modules/history-message/actions/history-message.action-types';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import historyMessageActions from '@shared/modules/history-message/actions/history-message.actions';
import { getStringId } from '@shared/utils/get-string-id.util';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';
import { MenuService } from '@shared/services/menu.service';
import { Partner } from '@pages/partners/classes/Partner';
import { FilterModalComponent } from '@shared/modules/mat-modal/components/filter-modal/filter-modal.component';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import differenceBy from 'lodash-es/differenceBy';
import uniqBy from 'lodash-es/uniqBy';
import { HttpErrorResponse } from '@angular/common/http';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { PositionsService } from '@pages/positions/services/positions.service';
import { CardTableService } from '@shared/modules/card-table/services/card-table.service';
import { PositionsApiService } from '@pages/positions/services/positions-api.service';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends EventBusService<PartnerState> {
  private readonly historyDeleteModalId = getStringId();
  private readonly projectDeleteModalId = getStringId();
  private readonly modalStateService: ModalStateService;
  private readonly modalService: MatModalService;
  private readonly translate: TranslateService;
  private readonly toast: ToastService;
  private readonly headerService: HeaderService;
  private readonly menuService: MenuService;

  constructor(
    private filteringService: FilteringService,
    private partnerApiService: PartnerApiService,
    private historyService: HistoryMessageService,
    private positionService: PositionsService,
    private cardTableService: CardTableService,
    private positionApiService: PositionsApiService,
    private injector: Injector
  ) {
    super(partnerInitialState);
    this.modalStateService = this.injector.get<ModalStateService>(ModalStateService);
    this.modalService = this.injector.get<MatModalService>(MatModalService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.toast = this.injector.get<ToastService>(ToastService);
    this.headerService = this.injector.get<HeaderService>(HeaderService);
    this.menuService = this.injector.get<MenuService>(MenuService);
  }

  getEncodedFilter(): string | undefined {
    const partnerFilters = this.getStateSnapshot().partnerFilters;
    const sortedFilters = removeBlankAttributes(partnerFilters);
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }
    return encodedFilters;
  }

  getPartner(page: string, perPage: string): Observable<ListData<Partner>> {
    const encodedFilters = this.getEncodedFilter();

    return this.partnerApiService.getPartners(page, perPage, encodedFilters).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  managePartners(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    const headerSource$ = this.headerService.eventBus.on(headerActionTypes.newEntityButtonClick);
    const partnerDetailSource$ = this.eventBus.on(partnerActionTypes.editPartner);

    return merge(of(headerSource$, partnerDetailSource$)).pipe(
      mergeAll(),
      switchMap(() => this.openFullHeightModal(contentComponent))
    );
  }

  manageProjects(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    return this.eventBus
      .on([partnerActionTypes.createProject, partnerActionTypes.editProject])
      .pipe(switchMap(() => this.openFullHeightModal(contentComponent)));
  }

  deleteProject(partnerId: number): Observable<any> {
    return this.eventBus.on(partnerActionTypes.deleteProject).pipe(
      tap((project: ProjectDetail) => {
        this.modalService.openConfirmModal({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.delete_confirm',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          modalText: this.translate.instant('partners.project_delete_confirm', {
            name: project.name,
          }),
          confirmModalId: this.projectDeleteModalId,
        });
      }),
      switchMap((project: ProjectDetail) =>
        this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
          map((confirmModalId) => ({
            confirmModalId,
            project,
          }))
        )
      ),
      filter(({ confirmModalId }) => confirmModalId === this.projectDeleteModalId),
      switchMap(({ project }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.partnerApiService.deleteProject(partnerId, project.id).pipe(
          tap(() => {
            this.toast.showSuccess('partners.project_delete_success');
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          }),
          concatMap((updatedProject: ProjectDetail) => {
            return this.getFirstHistoryPage(partnerId, updatedProject);
          }),
          concatMap((data) => {
            return this.refreshProjectTable(partnerId).pipe(mapTo(data));
          }),
          catchError((err) => {
            this.toast.showError('partners.project_delete_error');
            return of(err);
          }),
          finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  handleHistoryMessageCreation(
    partnerId: number,
    message: string,
    type: 'partner' | 'project'
  ): Observable<HistoryMessage> {
    this.historyService.setState({ isSaveButtonDisabled: true });
    const endpoint =
      type === 'partner'
        ? this.partnerApiService.createPartnerHistoryMessage(partnerId, message)
        : this.partnerApiService.createProjectHistoryMessage(partnerId, message);
    return endpoint.pipe(
      tap((newMessage: HistoryMessage) => {
        const { historyMessages, allMessagesCount } = this.getStateSnapshot();
        this.setState({
          historyMessages: [newMessage, ...historyMessages],
          allMessagesCount: allMessagesCount + 1,
        });
        this.historyService.eventBus.dispatch(historyMessageActions.clearInput());
        this.toast.showSuccess('partners.message_send_success');
      }),
      finalize(() => {
        this.historyService.setState({ isSaveButtonDisabled: false });
      })
    );
  }

  loadMoreHistory(
    partnerId: number,
    projectId: number,
    type: 'partner' | 'project'
  ): Observable<ListData<HistoryMessage>> {
    return this.historyService.eventBus.on(historyActionTypes.loadMore).pipe(
      withLatestFrom(this.select('currentHistoryPage')),
      switchMap(([_, currentPage]) => {
        const endpoint =
          type === 'partner'
            ? this.partnerApiService.getPartnerHistory(partnerId, currentPage + 1)
            : this.partnerApiService.getProjectHistory(projectId, currentPage + 1);
        return endpoint.pipe(
          tap(() => {
            this.toast.showSuccess('partners.load_more_history_success');
            this.setState({ currentHistoryPage: currentPage + 1 });
          })
        );
      })
    );
  }

  loadMoreProject(partnerId: number): Observable<ListData<ProjectDetail>> {
    const currentPage = this.getStateSnapshot().currentProjectPage;
    return this.partnerApiService.getProjects(partnerId, currentPage + 1).pipe(
      tap((listData) => {
        const projects = this.getStateSnapshot().projects;
        this.setState({
          projects: uniqBy([...projects, ...listData.data], 'id'),
          allProjectCount: listData.total,
          currentProjectPage: currentPage + 1,
        });
        this.toast.showSuccess('partners.load_more_projects_success');
      })
    );
  }

  openProjectStatusToggleModal(selectedProject: ProjectCardTableRow): Observable<any> {
    this.setState({ selectedProject });
    const statusTo =
      selectedProject.status === ProjectStatus.Active
        ? 'partners.toggle_status_to_inactive'
        : 'partners.toggle_status_to_active';

    return this.modalService.openConfirmModal({
      isModalDataLoading: false,
      isHeaderShown: false,
      cancelButtonText: 'common.cancel',
      approveButtonText: 'common.yes',
      isSaveButtonDisabled: false,
      modalText: this.translate.instant('partners.toggle_status_confirm', {
        status: this.translate.instant(statusTo),
      }),
    });
  }

  openDeleteHistoryModal(entityId: number, type: 'partner' | 'project'): Observable<any> {
    return this.historyService.openHistoryDeleteModal(this.historyDeleteModalId).pipe(
      concatMap(({ historyMessage }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        const endpoint =
          type === 'partner'
            ? this.partnerApiService.deletePartnerHistory(entityId, historyMessage.id)
            : this.partnerApiService.deleteProjectHistory(entityId, historyMessage.id);

        return endpoint.pipe(
          tap(() => {
            const currentHistories = this.getStateSnapshot().historyMessages;
            const currentCount = this.getStateSnapshot().allMessagesCount;
            this.setState({
              historyMessages: currentHistories.filter(
                (history) => history.id !== historyMessage.id
              ),
              allMessagesCount: currentCount - 1,
            });
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.toast.showSuccess('partners.message_deleted_success');
          }),
          catchError((err) => {
            this.toast.showError('partners.message_deleted_error');
            return of(err);
          }),
          finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  updateHistoryMessage(id: number, type: 'partner' | 'project'): Observable<HistoryMessage> {
    return this.historyService.eventBus.on(historyActionTypes.saveEditedHistory).pipe(
      tap(() => this.historyService.setState({ isSaveButtonDisabled: true })),
      switchMap((payload: { historyId: number; message: string }) => {
        const { historyId, message } = payload;
        const endpoint =
          type === 'partner'
            ? this.partnerApiService.updatePartnerHistoryMessage(id, historyId, message)
            : this.partnerApiService.updateProjectHistoryMessage(id, historyId, message);

        return endpoint.pipe(
          tap((newHistory: HistoryMessage) => {
            const currentHistories = this.getStateSnapshot().historyMessages;
            const index = currentHistories.findIndex((history) => history.id === newHistory.id);
            if (index > -1) {
              currentHistories[index] = newHistory;
              this.setState({ historyMessages: [...currentHistories] });
            }
            this.toast.showSuccess('partners.message_updated_success');
            this.historyService.eventBus.dispatch(historyMessageActions.clearInput());
          }),
          catchError((err) => {
            this.toast.showError('partners.message_updated_error');
            return of(err);
          }),
          finalize(() => this.historyService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  toggleProjectStatus(partnerId: number): Observable<ProjectDetail> {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      withLatestFrom(this.select('selectedProject')),
      filter(([_, selectedProject]: [undefined, ProjectCardTableRow]) => !!selectedProject),
      map(([_, selectedProject]: [undefined, ProjectCardTableRow]) => selectedProject),
      tap(() => this.modalStateService.setState({ isSaveButtonDisabled: true })),
      concatMap((project: ProjectCardTableRow) =>
        this.partnerApiService.updateProjectStatus(partnerId, project.id, project.status).pipe(
          tap(() => {
            this.setState({ selectedProject: undefined });
          }),
          concatMap((updatedProject: ProjectDetail) => {
            return this.getFirstHistoryPage(partnerId, updatedProject);
          }),
          mergeMap((data) => {
            return this.refreshProjectTable(partnerId).pipe(mapTo(data));
          }),
          tap(() => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          }),
          catchError((err) => {
            return of(err);
          }),
          finalize(() => {
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        )
      )
    );
  }

  deletePosition(projectId: number): Observable<unknown> {
    const confirmId = getStringId();
    return this.eventBus.on(partnerActionTypes.openModalToDeletePosition).pipe(
      tap(() => {
        this.modalService.openConfirmModal({
          isModalDataLoading: false,
          isHeaderShown: false,
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          approveButtonText: 'common.delete_confirm',
          modalText: 'partners.position_delete_confirm',
          confirmModalId: confirmId,
        });
      }),
      switchMap((position: ProjectPosition) => {
        return this.waitForConfirmAction<ProjectPosition>(confirmId, position);
      }),
      switchMap((position: ProjectPosition) => {
        return this.positionApiService
          .deletePosition(position.id)
          .pipe(this.refreshProjectPositionTable(projectId, 'partners.delete_position_message'));
      })
    );
  }

  togglePositionStatus(projectId: number): Observable<PositionDetail> {
    const confirmId = getStringId();
    return this.eventBus.on(partnerActionTypes.togglePositionStatus).pipe(
      tap(() => {
        this.modalService.openConfirmModal({
          isModalDataLoading: false,
          isHeaderShown: false,
          isSaveButtonDisabled: false,
          modalText: 'partners.position_status_modify_confirm',
          confirmModalId: confirmId,
        });
      }),
      switchMap((position: ProjectPosition) => {
        return this.waitForConfirmAction<ProjectPosition>(confirmId, position);
      }),
      switchMap((position: ProjectPosition) => {
        return this.positionApiService
          .toggleStatus(position.id, position.status)
          .pipe(this.refreshProjectPositionTable(projectId, 'partners.position_status_modify'));
      })
    );
  }

  handlePartnerFiltering(): Observable<any> {
    const partnerFilters = this.getStateSnapshot().partnerFilters;
    const sortedFilters = removeBlankAttributes(partnerFilters);
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }
    return this.partnerApiService.getPartners('1', '30', encodedFilters);
  }

  setFirstLetters(): Observable<string[]> {
    return this.partnerApiService.getPartnersFirstLetters().pipe(
      tap((res: string[]) => {
        this.setState({ firstLetters: res });
      })
    );
  }

  getPartnerFilters(): Observable<CardFilterInterface[]> {
    return this.partnerApiService.getPartnerFilters().pipe(
      map((res: any) => {
        return Object.keys(res).map((key) => res[key]);
      })
    );
  }

  setFilterState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      partnerFilters: { ...this.getStateSnapshot().partnerFilters, ...filterObj },
    };
    this.setState(newState);
  }

  saveEntity(
    form: FormGroup,
    type: 'partner' | 'project',
    isEditMode: boolean
  ): Observable<PartnerDetail | ProjectDetail> {
    return this.modalStateService.eventBus.on(modalActionTypes.saveButtonClick).pipe(
      tap(() => this.modalStateService.setState({ isSaveButtonDisabled: true })),
      map(() => form.getRawValue()),
      switchMap((entity: ProjectDto | PartnerDto) => {
        let endpoint: Observable<PartnerDetail | ProjectDetail>;
        if (type === 'partner') {
          endpoint = isEditMode
            ? this.partnerApiService.updatePartner(entity as PartnerDto).pipe(
                tap((updatedDetail: PartnerDetail) =>
                  this.setState({ headerText: updatedDetail.name })
                ),
                concatMap((updatedDetail: PartnerDetail) => {
                  return this.partnerApiService.getPartnerHistory(updatedDetail.id, 1).pipe(
                    tap((histories: ListData<HistoryMessage>) => {
                      this.setNewHistories(histories);
                    }),
                    catchError(() => of(updatedDetail)),
                    map(() => updatedDetail)
                  );
                })
              )
            : this.partnerApiService.createPartner(entity as PartnerDto);
        } else {
          const partnerId = this.getStateSnapshot().partnerDetail.id;
          endpoint = isEditMode
            ? this.partnerApiService.updateProject(entity as ProjectDto, partnerId).pipe(
                tap((updatedDetail: ProjectDetail) => {
                  this.setState({ headerText: updatedDetail.name });
                  this.eventBus.dispatch(partnerActions.projectUpdated(updatedDetail));
                }),
                concatMap((updatedDetail: ProjectDetail) => {
                  if (updatedDetail instanceof HttpErrorResponse) {
                    return of(updatedDetail);
                  }

                  return this.partnerApiService.getProjectHistory(updatedDetail.id, 1).pipe(
                    tap((histories: ListData<HistoryMessage>) => {
                      this.setNewHistories(histories);
                    }),
                    catchError(() => of(updatedDetail)),
                    map(() => updatedDetail)
                  );
                })
              )
            : this.partnerApiService.createProject(entity as ProjectDto, partnerId).pipe(
                concatMap((response) => {
                  if (response instanceof HttpErrorResponse) {
                    return of(response);
                  }
                  return this.partnerApiService.getPartnerHistory(partnerId, 1).pipe(
                    tap((histories: ListData<HistoryMessage>) => {
                      this.setNewHistories(histories);
                    }),
                    catchError(() => of(response)),
                    map(() => response)
                  );
                }),
                concatMap((data) => {
                  return this.refreshProjectTable(partnerId).pipe(mapTo(data));
                })
              );
        }

        return this.handleToasts(endpoint, type, isEditMode);
      })
    );
  }

  createPosition(projectId: number): Observable<PositionDetail | HttpErrorResponse> {
    return this.positionService.createPosition().pipe(
      concatMap((detail: PositionDetail | HttpErrorResponse) => {
        if (detail instanceof HttpErrorResponse) {
          return of(detail);
        }

        const { currentPage, perPage } = this.cardTableService.getStateSnapshot();
        return this.cardTableService
          .getItems(
            this.partnerApiService.getProjectPositions(projectId, 1, (currentPage + 1) * perPage)
          )
          .pipe(mapTo(detail));
      })
    );
  }

  loadMorePosition(projectId: number) {
    return this.cardTableService.loadMoreItems(
      projectId,
      this.partnerApiService.getProjectPositions.bind(this.partnerApiService)
    );
  }

  saveAsFilter(): Observable<any> {
    return this.menuService.eventBus.on(partnerActionTypes.saveAsPartnerFilter).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: true,
          cancelButtonText: 'common.dismiss',
          approveButtonText: 'common.save',
          modalTitle: 'common.filter_save_as',
          iconUrl: 'assets/image/building.svg',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'basic',
          confirmModalId: this.historyDeleteModalId,
          modalText: 'partners.message_delete_confirm',
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: FilterModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
      })
    );
  }

  deleteFilter(): Observable<any> {
    return this.menuService.eventBus.on(partnerActionTypes.deletePartnerFilter).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.delete_confirm',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          confirmModalId: this.historyDeleteModalId,
          modalText: 'partners.message_delete_confirm',
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: ConfirmModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
        console.log('open save as dialog');
      })
    );
  }

  resetFilters() {
    this.filteringService.eventBus.dispatch(filterActions.resetFiltersAction());
  }

  private handleToasts(
    endpoint: Observable<PartnerDetail | ProjectDetail>,
    type: 'partner' | 'project',
    isEditMode: boolean
  ): Observable<PartnerDetail | ProjectDetail> {
    const postFix = type === 'partner' ? 'partner' : 'project';
    const message = !isEditMode ? `partners.create_${postFix}` : `partners.update_${postFix}`;

    return endpoint.pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage(message, true));
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
      }),
      catchError((err) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      }),
      finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
    );
  }

  private setNewHistories(histories: ListData<HistoryMessage>): void {
    const currentHistories = this.getStateSnapshot().historyMessages;
    const newMessages = differenceBy(histories.data, currentHistories, 'id');
    this.setState({
      historyMessages: uniqBy([...newMessages, ...currentHistories], 'id'),
      allMessagesCount: histories.total,
    });
  }

  private refreshProjectPositionTable(projectId: number, toastText: string) {
    return (source: Observable<any>): Observable<any> =>
      source.pipe(
        concatMap(() => {
          const { currentPage, perPage } = this.cardTableService.getStateSnapshot();
          return this.cardTableService.getItems(
            this.partnerApiService.getProjectPositions(projectId, 1, (currentPage + 1) * perPage)
          );
        }),
        tap(() => {
          this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          this.toast.showSuccess(getGeneralMessage(toastText, true));
        }),
        catchError((err) => {
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        })
      );
  }

  private refreshProjectTable(partnerId: number): Observable<ListData<ProjectDetail>> {
    const { currentProjectPage } = this.getStateSnapshot();
    return this.partnerApiService
      .getProjects(partnerId, 1, (currentProjectPage + 1) * AppConstants.cardTableRowsPerPage)
      .pipe(
        tap((listData) => {
          this.setState({
            projects: [...listData.data],
            allProjectCount: listData.total,
          });
        })
      );
  }

  private openFullHeightModal(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    return this.modalService.openDialog({
      width: AppConstants.fullHeightModalWidth,
      data: {
        contentComponent,
      },
    });
  }

  private getFirstHistoryPage<T>(partnerId: number, mapToObject: T) {
    return this.partnerApiService.getPartnerHistory(partnerId, 1).pipe(
      tap((histories: ListData<HistoryMessage>) => {
        this.setNewHistories(histories);
      }),
      map(() => mapToObject),
      catchError((err) => of(err))
    );
  }

  private waitForConfirmAction<T>(confirmId: string, mapToObject: T) {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      filter((id) => id === confirmId),
      mapTo(mapToObject)
    );
  }
}
