import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionDisplayComponent } from './version-display.component';

@NgModule({
  declarations: [VersionDisplayComponent],
  imports: [CommonModule],
  exports: [VersionDisplayComponent],
})
export class VersionDisplayModule {}
