import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import { TippyPlacement } from '@shared/classes/TippyPlacement';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import reduce from 'lodash-es/reduce';
import uniq from 'lodash-es/uniq';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { MenuService } from '@shared/services/menu.service';

@Component({
  selector: 'app-dot-menu',
  templateUrl: './dot-menu.component.html',
  styleUrls: ['./dot-menu.component.scss'],
})
export class DotMenuComponent implements OnChanges {
  @Input() menuItems: DotMenuItem[] = [];
  @Input() placement: TippyPlacement = 'bottom-start';
  @Input() isEnabled = true;
  @Output() clicked = new EventEmitter<DotMenuItem>();

  constructor(private auth: AuthService, private menuService: MenuService) {}

  ngOnChanges(changes: SimpleChanges): void {
    let menuItems: DotMenuItem[] = changes.menuItems?.currentValue;
    if (!Array.isArray(menuItems)) {
      menuItems = [];
    }

    const permissions = reduce(
      menuItems,
      (accumulatedPermissions: UserPermission[], item: DotMenuItem) => {
        item.permissions.forEach((itemPermission) => accumulatedPermissions.push(itemPermission));
        return accumulatedPermissions;
      },
      []
    );

    this.isEnabled =
      menuItems.length > 0 &&
      AuthUtils.isContainUserPermission(uniq(permissions), this.auth.getUserRoles());
  }

  onMenuItemClicked(menu: DotMenuItem): void {
    if (menu.actionOnClick) {
      this.menuService.eventBus.dispatch(menu.actionOnClick);
    }

    this.clicked.emit(menu);
  }
}
