import { Observable, Subject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Action } from './Action';
import { ofType } from '../rxjs/of-type.operator';

export class EventBus {
  private eventBus$: Subject<Action>;

  constructor() {
    this.eventBus$ = new Subject<Action>();
  }

  /**
   * @template T - type of the payload
   */
  on<T>(type: string | string[]): Observable<T | {}> {
    return this.eventBus$.asObservable().pipe(ofType(type), pluck('payload'));
  }

  dispatch(action: Action): void {
    this.eventBus$.next(action);
  }
}
