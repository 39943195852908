import { PartnerDetailMappedData } from '@pages/partners/classes/PartnerDetailMappedData';
import { getPartnerFieldManagerKeyValueData } from '@pages/partners/utils/get-partner-field-manager-key-value-data.util';
import { getPartnerKeyValueData } from '@pages/partners/utils/get-partner-key-value-data.util';
import { getPartnerContactKeyValueData } from '@pages/partners/utils/get-partner-contact-key-value.util';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';

export function mapPartnerDetailToKeyValues(partnerDetail: PartnerDetail): PartnerDetailMappedData {
  const { partnerContacts, contractDate } = partnerDetail;
  const contactKeyValueData = getPartnerFieldManagerKeyValueData(partnerDetail);
  const partnerKeyValueData = getPartnerKeyValueData(partnerDetail);
  const partnerContactKeyValueData = getPartnerContactKeyValueData(partnerContacts, contractDate);

  return {
    partnerDetail,
    partnerContactKeyValueData,
    partnerKeyValueData,
    contactKeyValueData,
  };
}
