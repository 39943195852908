import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { EndpointsConfig } from '@config/endpoints.config';
import { ListData } from '@shared/classes/ListData';
import { CandidateListItem } from '@pages/candidates/classes/CandidateListItem';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { NormalizedCandidateDto } from '@pages/candidates/classes/NormalizedCandidateDto';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { AppConstants } from '@config/app.constant';
import { AuthService } from '@shared/modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CandidateApiService {
  constructor(private http: HttpService, private auth: AuthService) {}

  getCandidates(
    page: string,
    perPage: string,
    filter?: string
  ): Observable<ListData<CandidateListItem>> {
    const params: { [k: string]: any } = {
      page,
      perPage,
    };
    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.candidates, params);
  }

  getCandidateFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.candidatesFirstLetters);
  }

  getCandidateFilters(): Observable<any> {
    return this.http.get(EndpointsConfig.candidatesFilters);
  }

  createCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.post(EndpointsConfig.candidates, this.normalizeCandidateDto(candidateDto));
  }

  updateCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.put(
      EndpointsConfig.candidatesById(candidateDto.id),
      this.normalizeCandidateDto(candidateDto)
    );
  }

  getCandidateById(id: number): Observable<CandidateDetail> {
    return this.http.get(EndpointsConfig.candidatesById(id));
  }

  getDocuments(id: number): Observable<CandidateDocument[]> {
    return this.http.get(EndpointsConfig.candidateDocuments(id));
  }

  uploadDocument(candidateId: number, formData: FormData): Observable<CandidateDocument> {
    return this.http.post(EndpointsConfig.candidateDocuments(candidateId), formData);
  }

  getHistory(candidateId: number, page = 1): Observable<ListData<HistoryMessage>> {
    return this.http.get(EndpointsConfig.candidateHistory(candidateId), {
      page: page.toString(),
      perPage: AppConstants.historyMessagesPerPage.toString(),
    });
  }

  createHistoryMessage(candidateId: number, message: string): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.candidateHistory(candidateId), { message });
  }

  updateHistoryMessage(
    candidateId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.candidateHistoryWithId(candidateId, historyId), {
      message,
    });
  }

  deleteHistoryMessage(candidateId: number, historyId: number): Observable<unknown> {
    return this.http.delete(EndpointsConfig.candidateHistoryWithId(candidateId, historyId));
  }

  private normalizeCandidateDto(candidateDto: CandidateDto): NormalizedCandidateDto {
    const normalized: NormalizedCandidateDto = {
      ...candidateDto,
      cityPreferences: [],
      specializationPreferences: [],
    };
    if (!normalized.id) {
      delete normalized.id;
    }

    if (!normalized.profile.id) {
      delete normalized.profile.id;
    }

    normalized.jobTypePreferences = candidateDto.jobTypePreferences.filter((jobType) => {
      return !!jobType.typeId;
    });

    normalized.languages = candidateDto.languages.filter((language) => {
      return !!language.languageId;
    });

    normalized.cityPreferences = candidateDto.cityPreferences
      .map((city) => city.id)
      .filter((value) => !!value);

    normalized.specializationPreferences = candidateDto.specializationPreferences
      .map((specialization) => specialization.id)
      .filter((value) => !!value);

    return normalized;
  }
}
