<div class="datatable-container custom-table">
    <ngx-datatable
      class="datatable"
      [rows]="data"
      [columns]="columnConfigWithCustomCells"
      [columnMode]="columnMode.force"
      [scrollbarV]="true"
      [scrollbarH]="false"
      [draggable]="false"
      [virtualization]="false"
      [headerHeight]="headerHeight"
      [rowHeight]="rowHeight"
      [messages]="messages"
      [selectAllRowsOnPage]="false"
      [externalSorting]="true"
      [selectionType]="SelectionType.checkbox"
      (scroll)="onScroll($event.offsetY)"
      (activate)="onActivate($event)"
      (select)="onSelect($event)"
      (sort)="sortBy.emit($event)"
        (resize)="onResize()">
        <ng-content></ng-content>
    </ngx-datatable>
</div>

