import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  filterInitialState,
  FilterState,
} from '@shared/modules/event-bus/state/filters/filter.state';
import { Observable } from 'rxjs';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';
import { map, skip } from 'rxjs/operators';
import { decodeBase64 } from '@shared/utils/encrypt.util';
import { PartnerFilter } from '@pages/partners/classes/PartnerFilter';

@Injectable({
  providedIn: 'root',
})
export class FilteringService extends EventBusService<FilterState> {
  constructor() {
    super(filterInitialState);
  }

  setSelectedFilterState(selectedFilter: string) {
    this.setState({ selected_filter: selectedFilter });
  }

  handleFiltersReset(): Observable<any> {
    return this.eventBus.on(filterActionTypes.resetFilters);
  }

  getSavedFilter(filterKey: string): Observable<any> {
    return this.select('selected_filter').pipe(
      skip(1),
      map((filter: string) => {
        const decodedFilter = decodeBase64(filter);
        return JSON.parse(decodedFilter);
      }),
      map((obj: Partial<PartnerFilter>) => {
        return obj[filterKey];
      })
    );
  }
}
