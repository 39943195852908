import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { User } from '@shared/modules/auth/classes/User';

@UntilDestroy()
@Directive({
  selector: '[appUserLoggedIn]',
})
export class UserLoggedInDirective implements OnInit {
  private fallbackTemplate: TemplateRef<HTMLElement>;

  @Input()
  set appUserLoggedIn(templateRef: TemplateRef<HTMLElement>) {
    this.fallbackTemplate = templateRef;
  }

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.auth.loggedInUserChange
      .pipe(
        tap((user: User) => this.updateView(user)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private updateView(user: User): void {
    this.viewContainer.clear();
    if (user) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!user && this.fallbackTemplate) {
      this.viewContainer.createEmbeddedView(this.fallbackTemplate);
    }
  }
}
