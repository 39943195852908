export enum UserRole {
  SuperAdmin = 'super-admin',
  Admin = 'admin',
  FieldManager = 'field-manager',
  ProjectManager = 'project-manager',
  Partner = 'partner',
  Candidate = 'candidate',
  TechnicalUser = 'technical-user',
  Contact = 'contact',
  UnAuthorized = 'unAuthorized',
}
