import { ComponentType } from '@angular/cdk/overlay';

export interface SideFilterState {
  isOpen: boolean;
  currentFilter: ComponentType<any>;
}

export const sideFilterInitialState: SideFilterState = {
  isOpen: false,
  currentFilter: undefined,
};
