import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/button/button-base.components';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends ButtonBaseComponent {
  @Input() textAlign: 'center' | 'right' | 'left' = 'center';
  @Input() isWhite = false;
  @Input() hintText: string;
  @Input() iconUrl: string;
}
