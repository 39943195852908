import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LoaderState {
  show: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  showCount = 0;

  show() {
    this.showCount++;
    this.emitShow();
  }

  hide() {
    if (this.showCount > 0) {
      this.showCount--;
      this.emitShow();
    }
  }

  emitShow() {
    this.loaderSubject.next(<LoaderState>{ show: this.showCount > 0 });
  }
}
