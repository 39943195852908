<div class="card-filtering-container">
    <div class="d-flex align-items-center">
        <div class="d-inline-flex">
            <app-checkbox
              checkboxColor="red"
              [formControl]="checkBox"
              [checked]="isChecked"
              [isDisabled]="isDisabled"
              (isChecked)="onCheckboxChanged($event)"></app-checkbox>
            <p class="card-filter-title">{{title | translate}}</p>
        </div>
    </div>
    <div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
        <form [formGroup]="dateIntervalPicker">
            <app-form-field class="mb-0">
                <app-datepicker
                  formControlName="start"
                  placeholder="common.please_select"
                  [isHintShown]="false"
                  [maxDate]="dateIntervalPicker.get('end').value">
                </app-datepicker>
            </app-form-field>
            <app-form-field class="mb-0">
                <app-datepicker
                  formControlName="end"
                  placeholder="common.please_select"
                  [isHintShown]="false"
                  [minDate]="dateIntervalPicker.get('start').value">
                </app-datepicker>
            </app-form-field>
        </form>
    </div>
    <div class="filter-tags-wrapper">
        <app-filter-tag
          *ngIf="dateIntervalPicker.valid"
          [id]="1"
          [value]="tagValue"
          (deleteItem)="deleteItem()">
        </app-filter-tag>
    </div>
</div>

