import { Component, ElementRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardFilteringBaseComponent } from '@shared/modules/filtering/components/card-filtering-base/card-filtering-base.component';
import { FormControl } from '@angular/forms';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-truefalse-filtering',
  templateUrl: './truefalse-filtering.component.html',
  styleUrls: ['./truefalse-filtering.component.scss'],
})
export class TruefalseFilteringComponent extends CardFilteringBaseComponent implements OnInit {
  selectedItems = new FormControl();
  selected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  options = [
    { id: true, name: this.translate.instant('common.yes') },
    { id: false, name: this.translate.instant('common.no') },
  ];
  constructor(
    readonly eRef: ElementRef,
    readonly filteringService: FilteringService,
    private translate: TranslateService
  ) {
    super(eRef, filteringService);
  }

  ngOnInit() {
    this.listenTrueFalseSelectorChange();
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.deleteItem();
      });
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: boolean | undefined) => {
        this.setValueForControls(items);
      });
    return super.ngOnInit();
  }

  get selectedItem() {
    return this.selected.asObservable();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    this.filterValueChange.emit({
      [this.filterKey]: isChecked ? this.selectedItems.value.id : undefined,
    });
  }

  private setValueForControls(valueOfControl: boolean | undefined): void {
    if (typeof valueOfControl !== 'undefined') {
      const sortedItems = this.sortSelectedFromExisting(valueOfControl);
      this.selectedItems.setValue(sortedItems[0]);
    } else {
      this.selectedItems.reset();
    }
  }

  private sortSelectedFromExisting(trueFalseValue: boolean): Object[] {
    return this.options.filter((value) => value.id === trueFalseValue);
  }

  private listenTrueFalseSelectorChange(): void {
    this.selectedItems.valueChanges
      .pipe(
        tap((value) => {
          this.selected.next(value ? [value] : null);
          this.isDisabled = !value;
          this.isChecked = value ?? value;
          this.filterValueChange.emit({ [this.filterKey]: value ? value.id : undefined });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  deleteItem() {
    this.selectedItems.reset();
  }
}
