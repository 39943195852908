<div class="d-flex align-items-center">
    <app-button
            [type]="'button'"
            [buttonClass]="BUTTON_CLASS.ICON"
            [icon]="icon"
            [text]="''"
            (buttonClick)="navigate()">
    </app-button>
    <h1 class="ml-4">{{title | translate}}</h1>
</div>
