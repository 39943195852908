import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { Observable } from 'rxjs';

enum SuperRequired {
  ON_INIT = 'OnInit',
  ON_DESTROY = 'OnDestroy',
}

@UntilDestroy()
@Component({
  template: '',
})
export class CardFilteringBaseComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() filterKey: string;
  @Input() data: any;
  @Output() filterValueChange = new EventEmitter<Record<string, any>>();
  @ViewChild('dropDown', { static: false }) dropDown;
  @ViewChild('filterTag', { static: false }) filterTag;
  checkBox = new FormControl();
  isOpen = false;
  isChecked = false;
  isDisabled = true;

  constructor(readonly eRef: ElementRef, readonly filteringService: FilteringService) {}

  ngOnInit(skip?: boolean): SuperRequired.ON_INIT {
    if (!skip) {
      // common onInit logic
    }
    return SuperRequired.ON_INIT;
  }

  ngOnDestroy(skip?: boolean): SuperRequired.ON_DESTROY {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.ON_DESTROY;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      typeof this.dropDown === 'undefined' &&
      !(
        event.target.classList.contains('mat-calendar-body-selected') ||
        event.target.classList.contains('mat-calendar-spacer') ||
        event.target.classList.contains('mat-focus-indicator')
      )
    ) {
      this.isOpen = !!this.eRef.nativeElement.contains(event.target);
    } else if (this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = true;
    } else if (event.target.classList.contains('ng-option')) {
      this.isOpen = this.isOpen ?? this.isOpen;
    } else if (
      event.target.classList.contains('mat-calendar-body-selected') ||
      event.target.classList.contains('mat-calendar-spacer') ||
      event.target.classList.contains('mat-focus-indicator')
    ) {
      this.isOpen = this.isOpen ?? this.isOpen;
    } else {
      this.isOpen = false;
    }
  }

  listenFilterResetAction(): Observable<any> {
    return this.filteringService.handleFiltersReset().pipe(untilDestroyed(this));
  }

  listenSavedFilterChange(filterKey: string): Observable<any> {
    return this.filteringService.getSavedFilter(filterKey).pipe(untilDestroyed(this));
  }
}
