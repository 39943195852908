import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  cardTableInitialState,
  CardTableState,
} from '@shared/modules/event-bus/state/card-table/card-table.state';
import { Observable } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ListData } from '@shared/classes/ListData';
import { cardTableActionTypes } from '@shared/modules/card-table/actions/card-table.action-types';
import { uniqBy } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class CardTableService extends EventBusService<CardTableState> {
  constructor() {
    super(cardTableInitialState);
  }

  loadMoreItems(
    parentId: number,
    endpointToGetItems: (parentId: number, page: number) => Observable<ListData<any>>
  ) {
    return this.eventBus.on(cardTableActionTypes.loadMoreItems).pipe(
      withLatestFrom(this.select('rows'), this.select('currentPage')),
      switchMap(([_, currentRows, currentPage]: [void, any[], number]) =>
        endpointToGetItems(parentId, currentPage + 1).pipe(
          tap((listData: ListData<any>) => {
            this.setState({
              total: listData.total,
              currentPage: currentPage + 1,
              rows: uniqBy([...currentRows, ...listData.data], 'id'),
            });
          })
        )
      )
    );
  }

  getItems(endpointToGetItems: Observable<ListData<any>>): Observable<any> {
    return endpointToGetItems.pipe(
      tap((listData: ListData<any>) => {
        this.setState({
          total: listData.total,
          rows: listData.data,
        });
      })
    );
  }
}
