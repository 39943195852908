import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';

@Pipe({
  name: 'appFormatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return moment(new Date(value)).format(AppConstants.dateFormat);
  }
}
