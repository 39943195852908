<nav class="side-navigation d-flex justify-content-between flex-column">
    <ul>
        <li *ngFor="let sidenavItem of sidenavItems" routerLinkActive="link-active">
            <ng-container *ngIf="!sidenavItem.navigateTo; else hasNavigationRoute">
                <div class="icon-container" [inlineSVG]="sidenavItem.icon"></div>
            </ng-container>
            <ng-template #hasNavigationRoute>
                <a [routerLink]="sidenavItem.navigateTo" class="icon-container" [inlineSVG]="sidenavItem.icon"></a>
            </ng-template>
        </li>
    </ul>
    <ul class="bottom-nav">
        <li routerLinkActive="link-active">
            <a routerLink="users" class="icon-container">
                <img class="profile-image" [src]="(me$ | async)?.avatarThumb || 'assets/image/person-placeholder.svg'" alt="Profile icon">
            </a>
        </li>
        <li class="version-number">{{appVersion}}</li>
    </ul>
</nav>
