import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  modalText$: Observable<string>;
  private confirmModalId: string;

  constructor(private modalStateService: ModalStateService) {}

  ngOnInit(): void {
    this.modalStateService.setState({ isModalDataLoading: false });
    this.modalText$ = this.modalStateService.select('modalText');
    this.listenSaveButtonClick();
    this.modalStateService
      .select('confirmModalId')
      .pipe(untilDestroyed(this))
      .subscribe((id) => (this.confirmModalId = id));
  }

  ngOnDestroy(): void {
    this.modalStateService.resetModalStateOnDestroy();
  }

  private listenSaveButtonClick(): void {
    this.modalStateService.eventBus
      .on(modalActionTypes.saveButtonClick)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.modalStateService.eventBus.dispatch(
          modalActions.confirmButtonClickAction(this.confirmModalId)
        );
      });
  }
}
