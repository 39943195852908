import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends EventBusService<{}> {
  toggleSubject$ = new Subject<boolean>();

  constructor() {
    super({});
  }
}
