<div class="mb-40px mt-20px">
    <app-form-field>
        <app-text-field
          [label]="'common.name' | translate | appFirstLetterUppercase"
          [formControl]="filterName"
          type="text">
        </app-text-field>
    </app-form-field>
</div>

