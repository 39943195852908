<ng-container appSetLanguage>
    <app-global-loader></app-global-loader>

    <ng-container *ngIf="isBrowserSupported">
        <ng-template #userNotLoggedInFallbackTemplate>
            <router-outlet></router-outlet>
        </ng-template>

        <ng-container *appUserLoggedIn="userNotLoggedInFallbackTemplate">
            <div class="app-container">
                <section class="side-navigation">
                    <app-side-navigation class="d-block"></app-side-navigation>
                </section>

                <section class="main-content">
                    <app-header
                      [ngClass]="['main-content-header', isSideFilterOpened ? 'header-filter-opened' : 'header-filter-closed']"
                      [slideHeader]="slideHeader">
                    </app-header>
                    <app-side-filter></app-side-filter>
                    <main
                      appSetBodyBackground
                      [ngClass]="['main-content-body', isSideFilterOpened ? 'side-filter-opened' : 'side-filter-closed']">
                        <router-outlet></router-outlet>
                    </main>
                </section>
            </div>
        </ng-container>
    </ng-container>

    <div class="w-100 h-100" *ngIf="!isBrowserSupported">
        <app-browser-not-supported></app-browser-not-supported>
    </div>
</ng-container>
