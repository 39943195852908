import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { AppHeaderState } from '@shared/modules/event-bus/state/app-header/app-header.state';
import { AppStateService } from '@shared/services/app-state.service';
import { AppState } from '@shared/modules/event-bus/state/app/app.state';

@Injectable()
export class HeaderResolver implements Resolve<void> {
  constructor(private headerService: HeaderService, private appService: AppStateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let newHeaderState: Partial<AppHeaderState> = { filterCount: 0 };
    let newAppState: Partial<AppState> = { currentUrl: state.url };

    const { headerComponent: currentHeader, pageTitle: title } = route.data;

    if (currentHeader) {
      newAppState = { ...newAppState, currentHeader };
    }

    if (title) {
      newHeaderState = { ...newHeaderState, title };
    }

    this.headerService.setState(newHeaderState);
    this.appService.setState(newAppState);
  }
}
