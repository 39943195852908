<div class="candidate-detail-header h-100 d-flex align-items-center">
    <app-back-arrow-button class="back-button d-block" (buttonClick)="onNavigation()"></app-back-arrow-button>
    <ng-container *ngIf="isDetailLoaded$ | async">
        <div class="profile-image mr-10px" [appSetBackgroundImage]="profileImage$ | async"></div>
        <div class="mr-5px">{{name$ | async}} <span *ngIf="age > 0">({{age}})</span></div>
        <div class="member-status d-flex align-items-center justify-content-center"
          inlineSVG="assets/image/tick.svg"
          [tippy]="'candidates.member' | translate | appFirstLetterUppercase"
          variation="hint"
          placement="bottom">
        </div>
    </ng-container>
</div>
