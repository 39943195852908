import { Injectable, Injector } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  positionInitialState,
  PositionState,
} from '@shared/modules/event-bus/state/positions/position.state';
import { ComponentType } from '@angular/cdk/overlay';
import { merge, Observable, of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { headerActionTypes } from '@shared/modules/header/actions/header.action-types';
import {
  catchError,
  concatMap,
  finalize,
  map,
  mapTo,
  mergeAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppConstants } from '@config/app.constant';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { positionActionTypes } from '@pages/positions/actions/position.action-types';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { HttpErrorResponse } from '@angular/common/http';
import { PositionsApiService } from '@pages/positions/services/positions-api.service';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { getStringId } from '@shared/utils/get-string-id.util';
import { PositionListItem } from '@pages/positions/classes/PositionListItem';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { UpdateCandidatePositionPayload } from '@pages/positions/classes/board/UpdateCandidatePositionPayload';
import { CandidateBoardApiService } from '@pages/positions/services/candidate-board-api.service';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import positionActions from '../actions/position.actions';

@Injectable({
  providedIn: 'root',
})
export class PositionsService extends EventBusService<PositionState> {
  historyDeleteModalId = getStringId();
  private readonly modalService: MatModalService;
  private readonly modalStateService: ModalStateService;

  constructor(
    private headerService: HeaderService,
    private positionApiService: PositionsApiService,
    private toast: ToastService,
    private historyMessageService: HistoryMessageService,
    private filteringService: FilteringService,
    private candidateBoardApiService: CandidateBoardApiService,
    private injector: Injector
  ) {
    super(positionInitialState);
    this.modalService = this.injector.get<MatModalService>(MatModalService);
    this.modalStateService = this.injector.get<ModalStateService>(ModalStateService);
  }

  managePositions(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    const headerSource$ = this.headerService.eventBus.on(headerActionTypes.newEntityButtonClick);
    const positionDetailSource$ = this.eventBus.on(positionActionTypes.editPosition);

    return merge(of(headerSource$, positionDetailSource$)).pipe(
      mergeAll(),
      switchMap(() =>
        this.modalService.openDialog({
          width: AppConstants.fullHeightModalWidth,
          data: {
            contentComponent,
          },
        })
      )
    );
  }

  createPosition(): Observable<PositionDetail | HttpErrorResponse> {
    return this.eventBus.on(positionActionTypes.createPosition).pipe(
      switchMap((dto: PositionDto) => {
        return this.positionApiService.createPosition(dto).pipe(
          tap(() => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.toast.showSuccess(getGeneralMessage('positions.create', true));
          }),
          catchError((err) => {
            this.toast.showError(getGeneralMessage('positions.create', false));
            return of(err);
          }),
          finalize(() => {
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  updatePosition(): Observable<PositionDetail | HttpErrorResponse> {
    return this.eventBus.on(positionActionTypes.updatePosition).pipe(
      switchMap((dto: PositionDto) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.positionApiService.updatePosition(dto).pipe(
          concatMap((detail: PositionDetail) => {
            return this.historyMessageService
              .getFirstHistoryPage(this.positionApiService.getHistory(detail.id, 1))
              .pipe(mapTo(detail));
          }),
          tap((updatedDetail: PositionDetail) => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.eventBus.dispatch(positionActions.refreshDetails(updatedDetail));
            this.toast.showSuccess(getGeneralMessage('positions.modify', true));
          }),
          catchError((err) => {
            this.toast.showError(getGeneralMessage('positions.modify', false));
            return of(err);
          }),
          finalize(() => {
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  createHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.createHistory(
      positionId,
      this.positionApiService.createHistoryMessage.bind(this.positionApiService),
      this.positionApiService.getHistory(positionId, 1)
    );
  }

  updateHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.updateHistory(
      positionId,
      this.positionApiService.updateHistoryMessage.bind(this.positionApiService)
    );
  }

  openDeleteHistoryModal(positionId: number): Observable<any> {
    return this.historyMessageService.deleteHistory(
      positionId,
      this.positionApiService.deleteHistoryMessage.bind(this.positionApiService),
      this.positionApiService.getHistory(positionId, 1)
    );
  }

  loadMoreHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.loadMoreHistory(
      positionId,
      this.positionApiService.getHistory.bind(this.positionApiService)
    );
  }

  openAddCandidateToPositionModal(contentComponent: ComponentType<any>) {
    return this.eventBus.on(positionActionTypes.openModalToSearchCandidate).pipe(
      tap(() => {
        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });

        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'positions.board.add_candidate',
          iconUrl: 'assets/image/common/applicant-icon.svg',
        });
      })
    );
  }

  openRejectCandidateModal(contentComponent: ComponentType<any>) {
    return this.eventBus.on(positionActionTypes.openModalToRejectCandidate).pipe(
      tap(() => {
        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });

        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'positions.board.reason_title',
          iconUrl: 'assets/image/reject.svg',
        });
      }),
      switchMap(() => {
        return this.eventBus.on(positionActionTypes.confirmRejectedCandidate);
      }),
      withLatestFrom(this.select('selectedBoardColumn'), this.select('selectedCandidate')),
      tap(
        ([option, targetColumn, candidate]: [
          { optionId: number },
          BoardColumn,
          BoardCandidate
        ]) => {
          this.eventBus.dispatch(
            positionActions.updateCandidatePosition({
              targetColumn,
              candidate,
              optionId: option?.optionId,
            })
          );
        }
      )
    );
  }

  addCandidateToPosition(positionId: number) {
    return this.eventBus.on(positionActionTypes.addCandidateToPosition).pipe(
      withLatestFrom(this.select('selectedBoardColumn')),
      switchMap(([payload, column]: [{ candidateId: number }, BoardColumn]) => {
        return this.candidateBoardApiService
          .addCandidateToPosition(positionId, payload.candidateId, {
            statusId: column.id,
            statusOptionId: null,
          })
          .pipe(
            concatMap(() => this.triggerBoardRefresh(positionId)),
            tap(() => {
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            }),
            catchError((err) => {
              return of(err);
            })
          );
      })
    );
  }

  updateCandidatePosition(positionId: number): Observable<BoardCandidate> {
    return this.eventBus.on(positionActionTypes.updateCandidatePosition).pipe(
      switchMap((payload: UpdateCandidatePositionPayload) => {
        this.setState({ isBoardLoading: true });
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.candidateBoardApiService
          .updateCandidatePosition(positionId, payload.candidate.user.id, {
            statusId: payload.targetColumn.id,
            statusOptionId: payload.optionId || null,
          })
          .pipe(
            concatMap(() => this.triggerBoardRefresh(positionId)),
            catchError((err) => {
              this.toast.showError(getGeneralMessage('positions.board.modify', false));
              this.eventBus.dispatch(positionActions.restoreBoardToPreviousState());
              return of(err);
            }),
            finalize(() => {
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
              this.setState({ isBoardLoading: false });
            })
          );
      })
    );
  }

  setFirstLetters(): Observable<string[]> {
    return this.positionApiService.getPositionFirstLetters().pipe(
      tap((res: string[]) => {
        this.setState({ firstLetters: res });
      })
    );
  }

  setFilterState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      positionFilters: { ...this.getStateSnapshot().positionFilters, ...filterObj },
    };
    this.setState(newState);
  }

  getEncodedFilter(): string | undefined {
    const positionFilters = this.getStateSnapshot().positionFilters;
    const sortedFilters = removeBlankAttributes(positionFilters);
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }
    return encodedFilters;
  }

  getPositions(page: string, perPage: string): Observable<ListData<PositionListItem>> {
    const encodedFilters = this.getEncodedFilter();

    return this.positionApiService.getPositions(page, perPage, encodedFilters).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  getPositionFilters(): Observable<CardFilterInterface[]> {
    return this.positionApiService.getPositionFilters().pipe(
      map((res: any) => {
        return Object.keys(res).map((key) => res[key]);
      })
    );
  }

  resetFilters() {
    this.filteringService.eventBus.dispatch(filterActions.resetFiltersAction());
  }

  private triggerBoardRefresh(positionId: number): Observable<BoardCandidate[]> {
    return this.candidateBoardApiService.getBoardCandidates(positionId).pipe(
      tap((boardCandidates) => {
        this.setState({ boardCandidates });
        this.eventBus.dispatch(positionActions.triggerBoardRefresh());
      })
    );
  }
}
