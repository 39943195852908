import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterTypes } from '@shared/modules/filtering/classes/FilterTypes';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';

@Component({
  selector: 'app-filter-container-content',
  templateUrl: './filter-container-content.component.html',
  styleUrls: ['./filter-container-content.component.scss'],
})
export class FilterContainerContentComponent {
  @Input() numberOfActiveFilter = 0;
  @Input() translateKey: string;
  @Input() filters: CardFilterInterface[] = [];
  @Output() setFilter = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<void>();
  filterTypes = FilterTypes;
}
