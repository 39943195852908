import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  AppHeaderState,
  headerInitialState,
} from '@shared/modules/event-bus/state/app-header/app-header.state';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends EventBusService<AppHeaderState> {
  constructor() {
    super(headerInitialState);
  }
}
