import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import candidateActions from '@pages/candidates/actions/candidate.actions';
import { AppConfig } from '@config/app.config';
import { filter, map, tap } from 'rxjs/operators';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { getCandidateName } from '@pages/candidates/utils/get-candidate-name.util';
import { getCandidateAge } from '@pages/candidates/utils/get-candidate-age.util';

@Component({
  selector: 'app-candidate-detail-header',
  templateUrl: './candidate-detail-header.component.html',
  styleUrls: ['./candidate-detail-header.component.scss'],
})
export class CandidateDetailHeaderComponent implements OnInit {
  profileImage$: Observable<string>;
  name$: Observable<string>;
  age: number;
  isDetailLoaded$: Observable<boolean>;
  tooltipText: string;
  isAgeShown = true;
  isMemberIconShown = false;

  constructor(private candidateService: CandidatesService) {}

  ngOnInit(): void {
    this.isDetailLoaded$ = this.candidateService.select('isDetailLoaded');
    this.name$ = this.candidateService.select('candidateDetail').pipe(
      filter((detail: CandidateDetail) => !!detail),
      tap((detail: CandidateDetail) => {
        this.isMemberIconShown = detail.profile.isMember;
        this.age = getCandidateAge(detail.profile.birthDate);
      }),
      map((detail: CandidateDetail) => {
        return getCandidateName(detail.firstName, detail.lastName);
      })
    );
    this.profileImage$ = of('https://source.unsplash.com/100x100'); // TODO this is mock
  }

  onNavigation(): void {
    this.candidateService.eventBus.dispatch(
      candidateActions.navigateBack([AppConfig.candidatesUrl])
    );
  }
}
