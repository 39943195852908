import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateWithParamsPipe } from '@shared/modules/custom-pipes/pipes/translate-with-params.pipe';
import { AppFirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';

@NgModule({
  declarations: [TranslateWithParamsPipe, AppFirstLetterUppercasePipe, FormatDatePipe, TimeagoPipe],
  exports: [TranslateWithParamsPipe, AppFirstLetterUppercasePipe, FormatDatePipe, TimeagoPipe],
  imports: [CommonModule],
})
export class CustomPipesModule {}
