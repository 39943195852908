import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';
import { FormControl } from '@angular/forms';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';

@Component({
  selector: 'app-filter-container-header',
  templateUrl: './filter-container-header.component.html',
  styleUrls: ['./filter-container-header.component.scss'],
})
export class FilterContainerHeaderComponent {
  @Input() savedFilters: GetUsersFilter[] = [];
  @Input() filter: FormControl = new FormControl();
  @Input() menuConfig: DotMenuItem[] = [];
  @Output() selectedClicked = new EventEmitter<any>();
}
