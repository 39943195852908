import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ComponentType } from '@angular/cdk/overlay';

export interface ModalData {
  contentComponent: ComponentType<any>;
  title?: string;
  variant?: ModalTypes;
  secondaryButtonText?: string;
  primaryButtonText?: string;
}

export const defaultModalData: Omit<ModalData, 'contentComponent' | 'title'> = {
  variant: ModalTypes.FullHeight,
  secondaryButtonText: 'common.cancel',
  primaryButtonText: 'common.save',
};
