import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { AppStateService } from '@shared/services/app-state.service';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() slideHeader: boolean;
  currentHeader$: Observable<ComponentType<any>>;

  constructor(private appService: AppStateService) {
    super();
  }

  ngOnInit() {
    this.currentHeader$ = this.appService.select('currentHeader');
    return super.ngOnInit();
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
