import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  historyMessageInitialState,
  HistoryMessageState,
} from '@shared/modules/event-bus/state/history-message/history-message.state';
import { historyActionTypes } from '@shared/modules/history-message/actions/history-message.action-types';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ConfirmModalComponent } from '@shared/modules/mat-modal/components/confirm-modal/confirm-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { Observable, of } from 'rxjs';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { AppConstants } from '@config/app.constant';
import { getStringId } from '@shared/utils/get-string-id.util';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { ListData } from '@shared/classes/ListData';
import { uniqBy } from 'lodash-es';
import historyMessageActions from '@shared/modules/history-message/actions/history-message.actions';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';

@Injectable({
  providedIn: 'root',
})
export class HistoryMessageService extends EventBusService<HistoryMessageState> {
  historyDeleteModalId = getStringId();

  constructor(
    private modalService: MatModalService,
    private modalStateService: ModalStateService,
    private toast: ToastService
  ) {
    super(historyMessageInitialState);
  }

  resetHistoryMessageState(): void {
    this.setState({ ...historyMessageInitialState });
  }

  openHistoryDeleteModal(confirmModalId: string) {
    return this.eventBus.on(historyActionTypes.deleteHistory).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.delete_confirm',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          confirmModalId,
          modalText: 'partners.message_delete_confirm',
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: ConfirmModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
      }),
      switchMap((historyMessage: HistoryMessage) => {
        return this.modalStateService.eventBus
          .on(modalActionTypes.confirmButtonClick)
          .pipe(map((id: string) => ({ id, historyMessage })));
      }),
      filter(({ id }) => confirmModalId === id)
    );
  }

  deleteHistory(
    parentId: number,
    endpointToDeleteMessage: (positionId: number, historyId: number) => Observable<unknown>,
    endpointToGetFirstPage: Observable<ListData<HistoryMessage>>
  ): Observable<any> {
    return this.openHistoryDeleteModal(this.historyDeleteModalId).pipe(
      concatMap(({ historyMessage }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return endpointToDeleteMessage(parentId, historyMessage.id).pipe(
          tap(() => {
            const { historyMessages, allMessageCount } = this.getStateSnapshot();
            this.setState({
              historyMessages: historyMessages.filter(
                (history) => history.id !== historyMessage.id
              ),
              allMessageCount: allMessageCount - 1,
            });
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.toast.showSuccess('partners.message_deleted_success');
          }),
          concatMap((data: any) => {
            const { historyMessages } = this.getStateSnapshot();
            if (historyMessages.length <= AppConstants.historyMessagesPerPage) {
              return this.getFirstHistoryPage(endpointToGetFirstPage);
            }

            return of(data);
          }),
          catchError((err) => {
            this.toast.showError('partners.message_deleted_error');
            return of(err);
          }),
          finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  updateHistory(
    id: number,
    endpointToUpdate: (id: number, historyId: number, message: string) => Observable<HistoryMessage>
  ): Observable<ListData<HistoryMessage>> {
    return this.eventBus.on(historyActionTypes.saveEditedHistory).pipe(
      tap(() => {
        this.setState({ isSaveButtonDisabled: true });
      }),
      switchMap(({ message, historyId }: { message: string; historyId: number }) => {
        return endpointToUpdate(id, historyId, message).pipe(
          tap((updatedMessage: HistoryMessage) => {
            const { historyMessages } = this.getStateSnapshot();
            const index = historyMessages.findIndex(
              (existingMessage) => existingMessage.id === historyId
            );
            if (index > -1) {
              historyMessages[index] = updatedMessage;
            }
            this.setState({ historyMessages: uniqBy([...historyMessages], 'id') });
            this.eventBus.dispatch(historyMessageActions.clearInput());
            this.toast.showSuccess(getGeneralMessage('candidates.modify_message', true));
          }),
          catchError((err) => {
            this.toast.showSuccess(getGeneralMessage('candidates.modify_message', false));
            return of(err);
          }),
          finalize(() => {
            this.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  createHistory(
    id: number,
    endpointToCreate: (id, message) => Observable<HistoryMessage>,
    endpointToGetFirstPage: Observable<ListData<HistoryMessage>>
  ): Observable<ListData<HistoryMessage>> {
    return this.eventBus.on(historyActionTypes.saveNewHistory).pipe(
      tap(() => {
        this.setState({ isSaveButtonDisabled: true });
      }),
      switchMap((message: string) => {
        return endpointToCreate(id, message).pipe(
          tap(() => {
            this.toast.showSuccess(getGeneralMessage('candidates.create_message', true));
            this.eventBus.dispatch(historyMessageActions.clearInput());
          }),
          concatMap(() => this.getFirstHistoryPage(endpointToGetFirstPage)),
          catchError((err) => {
            this.toast.showSuccess(getGeneralMessage('candidates.create_message', false));
            return of(err);
          }),
          finalize(() => {
            this.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  loadMoreHistory(
    parentId: number,
    endpointToGetPage: (parentId, currentPage) => Observable<ListData<HistoryMessage>>
  ): Observable<ListData<HistoryMessage>> {
    return this.eventBus.on(historyActionTypes.loadMore).pipe(
      withLatestFrom(this.select('currentHistoryPage')),
      switchMap(([_, currentPage]) => {
        return endpointToGetPage(parentId, currentPage + 1).pipe(
          tap((history: ListData<HistoryMessage>) => {
            const { historyMessages } = this.getStateSnapshot();
            this.toast.showSuccess('partners.load_more_history_success');
            this.setState({
              currentHistoryPage: currentPage + 1,
              allMessageCount: history.total,
              historyMessages: uniqBy([...historyMessages, ...history.data], 'id'),
            });
          })
        );
      })
    );
  }

  getFirstHistoryPage(
    endpoint: Observable<ListData<HistoryMessage>>
  ): Observable<ListData<HistoryMessage>> {
    const { historyMessages } = this.getStateSnapshot();
    return endpoint.pipe(
      tap((history: ListData<HistoryMessage>) => {
        this.setState({
          historyMessages: uniqBy([...history.data, ...historyMessages], 'id'),
          allMessageCount: history.total,
        });
      })
    );
  }
}
