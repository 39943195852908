export function removeBlankAttributes(object) {
  return Object.keys(object)
    .filter((k) => !(object[k] == null || object[k] === '' || object[k].length <= 0))
    .reduce((a, k) => ({ ...a, [k]: object[k] }), {});
}

export function removeBlankAttributesFromNestedObj(object) {
  return Object.keys(object)
    .filter((k) => !(object[k] == null || object[k] === '' || object[k].length <= 0))
    .reduce((a, k) => {
      a[k] =
        typeof object[k] === 'object' ? removeBlankAttributesFromNestedObj(object[k]) : object[k];
      return a;
    }, {});
}
