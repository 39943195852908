import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TokenKeysConfig } from '@config/token-keys.config';
import { environment } from '@environments/environment';
import { IAuthStorageStrategy } from '../classes/IAuthStorageStrategy';

@Injectable()
export class CookieAuthStorageService implements IAuthStorageStrategy {
  constructor(private cookieService: CookieService) {}

  readAuthToken(): string {
    return this.cookieService.get(TokenKeysConfig.TokenKey);
  }

  readCSRFToken(): string {
    return this.cookieService.get(TokenKeysConfig.CSRFTokenKey);
  }

  readRefreshToken(): string {
    return this.cookieService.get(TokenKeysConfig.RefreshTokenKey);
  }

  removeAll(): void {
    this.cookieService.deleteAll('/', environment.cookieDomain);
  }

  removeAuthToken(): void {
    this.cookieService.delete(TokenKeysConfig.TokenKey);
  }

  removeCSRFToken(): void {
    this.cookieService.delete(TokenKeysConfig.CSRFTokenKey);
  }

  removeRefreshToken(): void {
    this.cookieService.delete(TokenKeysConfig.RefreshTokenKey);
  }

  removeByKey(key: string): void {
    this.cookieService.delete(key);
  }

  setAuthToken(token: string): void {
    this.cookieService.set(TokenKeysConfig.TokenKey, token);
  }

  setRefreshToken(refreshToken: string): void {
    this.cookieService.set(TokenKeysConfig.RefreshTokenKey, refreshToken);
  }

  setCSRFToken(CSRFToken: string): void {
    this.cookieService.set(TokenKeysConfig.CSRFTokenKey, CSRFToken);
  }

  setCustom(key: string, value: string): void {
    this.cookieService.set(key, value);
  }
}
