import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from '../button-base.components';

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
})
export class FabButtonComponent extends ButtonBaseComponent {
  @Input() icon = '';
  @Input() disabled: boolean;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
