import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends FormInputBaseComponent implements OnInit {
  @Input() checkboxColor: 'blue' | 'red' = 'blue';
  @Input() checked = false;
  @Input() set isDisabled(value: boolean) {
    this.setDisabledState(value);
  }
  @Output() isChecked: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }

  onCheckboxChange($event: any) {
    this.isChecked.emit($event.target.checked);
  }
}
