import { CandidateFilter } from '@pages/candidates/classes/CandidateFilter';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';

export interface CandidateState {
  candidateFilters: CandidateFilter;
  firstLetters: string[];
  candidateDetail: CandidateDetail;
  candidateAge: number;
  historyMessages: HistoryMessage[];
  allMessageCount: number;
  currentHistoryPage: number;
  isDetailLoaded: boolean;
}

export const candidateInitialState: CandidateState = {
  candidateFilters: {
    name: '',
    email: '',
    phone: '',
    is_adult: undefined,
    is_member: undefined,
    membership_start_date: undefined,
    membership_end_date: undefined,
    lung_filter_end_date: undefined,
    medical_certificate_end_date: undefined,
    address_id: [],
    city_preference_id: [],
    user_specialization_preference_id: [],
    job_type_preference_id: [],
    letter_filter: '',
    sortBy: [],
  },
  firstLetters: [],
  candidateDetail: undefined,
  candidateAge: -1,
  historyMessages: [],
  allMessageCount: 0,
  currentHistoryPage: 1,
  isDetailLoaded: false,
};
