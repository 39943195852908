import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { KeyValue } from '@shared/classes/KeyValue';

export function getPartnerFieldManagerKeyValueData(detail: PartnerDetail): KeyValue<string>[] {
  return [
    {
      key: 'common.name',
      value: detail.fieldManager.name,
    },
    {
      key: 'common.email',
      value: detail.fieldManager.email,
    },
    {
      key: 'common.phone_number',
      value: detail.fieldManager.phone,
    },
  ];
}
