import { Component, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';

@Component({
  selector: 'app-filter-option-template',
  templateUrl: './filter-option-template.component.html',
  styleUrls: ['./filter-option-template.component.scss'],
})
export class FilterOptionTemplateComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
}
