import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { FilteringManageService } from '@shared/modules/filtering/services/filtering-manage.service';

@UntilDestroy()
@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit, OnDestroy {
  filterName = new FormControl('', [Validators.required]);
  modalText$: Observable<string>;
  isEditMode: boolean;
  constructor(
    private modalStateService: ModalStateService,
    private filteringManageService: FilteringManageService
  ) {}

  ngOnInit(): void {
    this.modalStateService.setState({ isModalDataLoading: false });
    this.modalText$ = this.modalStateService.select('modalText');
    this.isEditMode = this.modalStateService.getStateSnapshot().isEditMode;
    this.listenSaveButtonClick();
    this.listenFormValueChanges();
    this.filterName.patchValue(
      this.isEditMode ? this.filteringManageService.getStateSnapshot().filter.name : ''
    );
  }

  ngOnDestroy(): void {
    this.modalStateService.resetModalStateOnDestroy();
    this.filterName.reset();
  }

  listenFormValueChanges() {
    this.filterName.valueChanges
      .pipe(
        debounceTime(300),
        map(() => {
          return this.filterName.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.modalStateService.setState({ isSaveButtonDisabled });
        this.filteringManageService.setFilterManageState({ name: this.filterName.value });
      });
  }

  private listenSaveButtonClick(): void {
    this.filteringManageService.saveFilter(this.isEditMode).pipe(untilDestroyed(this)).subscribe();
  }
}
