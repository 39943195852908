import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardFilteringBaseComponent } from '@shared/modules/filtering/components/card-filtering-base/card-filtering-base.component';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-dropdown-filtering',
  templateUrl: './dropdown-filtering.component.html',
  styleUrls: ['./dropdown-filtering.component.scss'],
})
export class DropdownFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  selectedItems = new FormControl();
  selected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  idOfSelectedItems: number[];

  constructor(readonly eRef: ElementRef, readonly filteringService: FilteringService) {
    super(eRef, filteringService);
  }

  get selectedItem() {
    return this.selected.asObservable();
  }

  ngOnInit() {
    this.listenDropDownChange();
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: number[] | undefined) => {
        this.setValueForControls(items);
      });
    this.selected.pipe(delay(200), untilDestroyed(this)).subscribe((values) => {
      this.isOpen = values?.length > 0;
    });
    return super.ngOnInit();
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    if (isChecked) {
      this.filterValueChange.emit({ [this.filterKey]: this.idOfSelectedItems });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  customSearchFn(term: string, item: any) {
    const arrayOfTerm = term.toLocaleLowerCase().split(' ');
    const arrayOfItem = item.name.split(' ');
    let isExist = false;
    isExist = arrayOfItem.some((i: string) => {
      return arrayOfTerm.some((t: string) => {
        return i.toLocaleLowerCase().startsWith(t);
      });
    });
    return isExist;
  }

  deleteItem(id: number) {
    const newSelectedItems = this.selectedItems.value.filter((item) => {
      return item.id !== id;
    });
    this.selectedItems.patchValue(newSelectedItems);
    this.isOpen = newSelectedItems.length > 0;
  }

  private listenDropDownChange(): void {
    this.selectedItems.valueChanges
      .pipe(
        tap((object) => {
          let idOfArray = object;
          this.idOfSelectedItems = object;
          if (!(object == null || object === '' || object.length <= 0)) {
            this.isDisabled = false;
            this.isChecked = true;
            idOfArray = idOfArray.map((value) => value.id);
            this.idOfSelectedItems = idOfArray;
          } else {
            this.isDisabled = true;
            this.isChecked = false;
          }
          this.selected.next(object);
          this.filterValueChange.emit({ [this.filterKey]: idOfArray });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setValueForControls(valueOfControl: any): void {
    if (typeof valueOfControl !== 'undefined') {
      const sortedItems = this.sortSelectedFromExisting(valueOfControl);
      this.selectedItems.setValue(sortedItems);
    } else {
      this.resetFormControls();
    }
  }

  private sortSelectedFromExisting(arrayOfIds: number[]): Object[] {
    return this.data.filter((value) => arrayOfIds.includes(value.id));
  }

  private resetFormControls(): void {
    this.selectedItems.reset();
  }
}
