import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonBaseComponent } from '@shared/modules/button/button-base.components';
import { PrimaryButtonComponent } from '@shared/modules/button/primary-button/primary-button.component';
import { FabButtonComponent } from '@shared/modules/button/fab-button/fab-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { FilterMenuButtonComponent } from './filter-menu-button/filter-menu-button.component';
import { RoundedButtonComponent } from './rounded-button/rounded-button.component';
import { BackArrowButtonComponent } from './back-arrow-button/back-arrow-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { LoadMoreButtonComponent } from './load-more-button/load-more-button.component';

@NgModule({
  declarations: [
    ButtonBaseComponent,
    PrimaryButtonComponent,
    FabButtonComponent,
    FilterMenuButtonComponent,
    RoundedButtonComponent,
    BackArrowButtonComponent,
    IconButtonComponent,
    LoadMoreButtonComponent,
  ],
  imports: [CommonModule, TranslateModule, InlineSVGModule, CustomPipesModule, TippyModule],
  exports: [
    PrimaryButtonComponent,
    FabButtonComponent,
    FilterMenuButtonComponent,
    RoundedButtonComponent,
    BackArrowButtonComponent,
    IconButtonComponent,
    LoadMoreButtonComponent,
  ],
})
export class ButtonModule {}
