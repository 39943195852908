import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  filterManageInitialState,
  FilterManageState,
} from '@shared/modules/event-bus/state/filters/filter-manage.state';
import { Observable, of } from 'rxjs';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { SaveUsersFilter } from '@shared/classes/users/SaveUsersFilter';
import { UserFilterApiService } from '@shared/services/user-filter-api.service';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';

@Injectable({
  providedIn: 'root',
})
export class FilteringManageService extends EventBusService<FilterManageState> {
  constructor(
    private modalStateService: ModalStateService,
    private userApiService: UserFilterApiService,
    private toast: ToastService
  ) {
    super(filterManageInitialState);
  }

  resetFilterManageState(): void {
    this.setState({ filter: filterManageInitialState.filter });
  }

  setFilterManageState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      filter: { ...this.getStateSnapshot().filter, ...filterObj },
    };
    this.setState(newState);
  }

  deleteFilter(): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      map(() => {
        return this.getStateSnapshot().filter.id;
      }),
      switchMap((filterId: number) => {
        return this.userApiService.deleteUserFilterById(filterId).pipe(
          tap((filters) => {
            this.setState({ savedFilters: filters });
            this.toast.showSuccess(getGeneralMessage('common.delete_filter', true));
          }),
          catchError((res) => {
            this.toast.showError(getGeneralMessage('common.delete_filter', true));
            return of(res);
          }),
          finalize(() => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.resetFilterManageState();
          })
        );
      })
    );
  }

  saveFilter(isEditMode: boolean): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.saveButtonClick).pipe(
      tap(() => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
      }),
      map(() => {
        return this.getStateSnapshot().filter;
      }),
      switchMap((body: SaveUsersFilter) => {
        const endpoint: Observable<GetUsersFilter[] | GetUsersFilter> = isEditMode
          ? this.userApiService
              .updateUserFilterById(body.id, body)
              .pipe(catchError((res) => of(res)))
          : this.userApiService.saveUserFilter(body).pipe(catchError((res) => of(res)));
        return this.handleToast(endpoint, isEditMode);
      })
    );
  }

  private handleToast(
    endpoint: Observable<GetUsersFilter[] | GetUsersFilter>,
    isEditMode: boolean
  ) {
    const message = !isEditMode ? `common.create_filter` : `common.update_filter`;

    return endpoint.pipe(
      tap((filters: GetUsersFilter[]) => {
        this.setState({ savedFilters: filters });
        this.toast.showSuccess(getGeneralMessage(message, true));
      }),
      catchError((err) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      }),
      finalize(() => {
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
        this.resetFilterManageState();
      })
    );
  }
}
