import { ComponentType } from '@angular/cdk/overlay';
import { Office } from '@pages/partners/classes/Office';
import { Country } from '@shared/classes/Country';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';

export interface AppState {
  currentUrl: string;
  currentHeader: ComponentType<any>;
  isLoginSubmitLoading: boolean;
  isForgotPasswordSubmitLoading: boolean;
  offices: Office[];
  isOfficesLoaded: boolean;
  countries: Country[];
  isCountriesLoaded: boolean;
  sources: Source[];
  isSourcesLoaded: boolean;
  jobTypes: JobType[];
  isJobTypesLoaded: boolean;
  specializations: Specialization[];
  isSpecializationsLoaded: boolean;
  languages: Language[];
  isLanguagesLoaded: boolean;
  languageLevels: LanguageLevel[];
  isLanguageLevelsLoaded: boolean;
  documentTypes: CandidateDocumentType[];
  isDocumentTypesLoaded: boolean;
  employmentTypes: EmploymentType[];
  isEmploymentTypesLoaded: boolean;
  educationTypes: EmploymentType[];
  isEducationTypesLoaded: boolean;
  workExperiences: WorkExperience[];
  isWorkExperiencesLoaded: boolean;
}

export const appInitialState: AppState = {
  currentUrl: '',
  currentHeader: undefined,
  isLoginSubmitLoading: false,
  isForgotPasswordSubmitLoading: false,
  offices: [],
  isOfficesLoaded: false,
  countries: [],
  isCountriesLoaded: false,
  sources: [],
  isSourcesLoaded: false,
  jobTypes: [],
  isJobTypesLoaded: false,
  specializations: [],
  isSpecializationsLoaded: false,
  languages: [],
  isLanguagesLoaded: false,
  languageLevels: [],
  isLanguageLevelsLoaded: false,
  documentTypes: [],
  isDocumentTypesLoaded: false,
  employmentTypes: [],
  isEmploymentTypesLoaded: false,
  educationTypes: [],
  isEducationTypesLoaded: false,
  workExperiences: [],
  isWorkExperiencesLoaded: false,
};
