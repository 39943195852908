import { AppConstants } from '@config/app.constant';

export interface CardTableState {
  rows: any[];
  total: number;
  currentPage: number;
  perPage: number;
}

export const cardTableInitialState = {
  rows: [],
  total: 0,
  currentPage: 1,
  perPage: AppConstants.cardTableRowsPerPage,
};
