<div class="main-page-header d-flex justify-content-between align-items-center">
    <div class="d-flex">
        <app-filter-menu-button
          [isOpen]="isSideFilterOpen"
          (buttonClick)="onSideFilterToggle()">
        </app-filter-menu-button>
        <h1 class="page-header-title">
            {{(headerTitle$ | async) | translate}}
            <ng-container *ngIf="filteredResultCount$ | async as filteredResultCount">
                <span>({{filteredResultCount}})</span>
            </ng-container>
        </h1>
    </div>
    <div>
        <app-primary-button
          *appUserHasPermission="[createPartnerPermission]"
          type="submit"
          [style]="'filled'"
          [text]="'page_header.header_button_title' | appTranslateWithParams : headerButtonParams$ | async"
          color="basic"
          [buttonClass]="'header-button'"
          (buttonClick)="onButtonClick()">
        </app-primary-button>
    </div>
</div>
