<div class="modal-frame" [ngClass]="{'full-height-modal-container': variant === modalTypes.FullHeight}">
    <div *ngIf="(isHeaderShown$ | async) && !isModalDataLoading" class="modal-title">
        <app-modal-header class="d-inline-block w-100 h-100" [dialogRef]="dialogRef"></app-modal-header>
    </div>
    <div class="modal-content mat-typography" mat-dialog-content>
        <ng-container #content></ng-container>
    </div>
    <div *ngIf="!isModalDataLoading" class="modal-actions" mat-dialog-actions align="end">
        <app-modal-footer
          class="d-inline-block"
          (primaryButtonClicked)="onSaveButtonClicked()"
          [primaryButtonDisabled]="isSaveDisabled$ | async"
          [primaryButtonText]="(approveButtonText$ | async) | translate"
          [secondaryButtonText]="(cancelButtonText$ | async) | translate"
          [dialogRef]="dialogRef">
        </app-modal-footer>
    </div>

    <div *ngIf="isModalDataLoading" class="loader d-flex justify-content-center align-items-center h-100 w-100">
        <app-loading class="d-block"></app-loading>
    </div>
</div>
