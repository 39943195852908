import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { getStringId } from '@shared/utils/get-string-id.util';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';

@UntilDestroy()
@Component({
  template: '',
})
export class FormInputBaseComponent
  extends BaseComponent
  implements ControlValueAccessor, OnChanges, OnDestroy {
  @ContentChild('customFieldLabel', { read: TemplateRef }) customLabelTemplate: TemplateRef<any>;
  @Input() id: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() alwaysShowErrors = false;
  @Input() hintText: string;
  @Input() isHintShown = false;
  @Input() customError: { error: string; message: string };
  hintType = HintType;
  textFieldId = getStringId();
  changeSubject$ = new Subject<SimpleChanges>();

  onChange: (value: string) => void;
  onTouched: () => void;
  value: any;
  control: AbstractControl | FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    this.changeSubject$.next(changes);
  }

  ngOnDestroy(skip?: boolean) {
    this.changeSubject$.complete();
    return super.ngOnDestroy(skip);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  getErrorMessage(): string {
    const errorPrefix = 'FORM_COMPONENTS.ERROR.';
    let errorSuffix = '';
    if (this.control.pristine && !this.alwaysShowErrors) {
      return errorSuffix;
    }
    if (this.customError) {
      errorSuffix = this.handleCustomError();
    } else if (this.control.hasError('required')) {
      errorSuffix = 'required';
    } else {
      errorSuffix = this.control.errors ? 'value-invalid' : '';
    }
    return errorSuffix ? errorPrefix + errorSuffix : errorSuffix;
  }

  private handleCustomError(): string {
    return this.control.hasError(this.customError.error) ? this.customError.message : '';
  }
}
