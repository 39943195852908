import values from 'lodash-es/values';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { UserRole } from './UserRole';

function applyAllPermissions() {
  return values(UserPermission);
}

export const UserRolePermission = {};
UserRolePermission[UserRole.SuperAdmin] = applyAllPermissions();
UserRolePermission[UserRole.Admin] = applyAllPermissions();
UserRolePermission[UserRole.FieldManager] = [
  UserPermission.CreatePartner,
  UserPermission.UpdatePartner,
  UserPermission.ReadPartner,
  UserPermission.CreatePartnerHistory,
  UserPermission.UpdatePartnerHistory,
  UserPermission.DeletePartnerHistory,
];
UserRolePermission[UserRole.ProjectManager] = [UserPermission.ReadPartner];
UserRolePermission[UserRole.Contact] = [];
UserRolePermission[UserRole.TechnicalUser] = [];
UserRolePermission[UserRole.Partner] = [];
