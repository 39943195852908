import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';
import { AppConstants } from '@config/app.constant';

@Component({
  selector: 'app-add-tag-option-template',
  templateUrl: './add-tag-option-template.component.html',
  styleUrls: ['./add-tag-option-template.component.scss'],
})
export class AddTagOptionTemplateComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
  @Output() itemAdded = new EventEmitter<string>();
  minInputLength = AppConstants.minInputLength;

  onAddNewItem(term: string) {
    this.itemAdded.emit(term);
  }
}
