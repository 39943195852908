import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';
import { SaveUsersFilter } from '@shared/classes/users/SaveUsersFilter';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';

@Injectable({
  providedIn: 'root',
})
export class UserFilterApiService {
  constructor(private httpService: HttpService) {}

  getUsersFilters(type: string): Observable<GetUsersFilter[]> {
    const params = {
      type,
    };
    return this.httpService.get(EndpointsConfig.usersFilters, params);
  }

  saveUserFilter(body: SaveUsersFilter): Observable<GetUsersFilter[]> {
    return this.httpService.post(EndpointsConfig.usersFilters, body);
  }

  updateUserFilterById(id: number, body: SaveUsersFilter): Observable<GetUsersFilter[]> {
    return this.httpService.put(`${EndpointsConfig.usersFilters}/${id}`, body);
  }

  deleteUserFilterById(id: number): Observable<GetUsersFilter[]> {
    return this.httpService.delete(`${EndpointsConfig.usersFilters}/${id}`);
  }
}
