<div class="toast-container d-flex align-items-start justify-content-between">
    <div class="d-flex">
        <div class="toast-icon" *ngIf="iconUrl" [inlineSVG]="iconUrl"></div>
        <div class="toast-message">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="toast-close-icon">
        <button type="button" (click)="onClose()" inlineSVG="assets/image/close-icon.svg"></button>
    </div>
</div>
