import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetBackgroundImageDirective } from '@shared/modules/custom-directives/set-background-image.directive';
import { SetBodyBackgroundDirective } from './set-body-background.directive';

@NgModule({
  declarations: [SetBackgroundImageDirective, SetBodyBackgroundDirective],
  imports: [CommonModule],
  exports: [SetBackgroundImageDirective, SetBodyBackgroundDirective],
})
export class CustomDirectivesModule {}
