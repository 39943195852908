import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { candidateActionTypes } from '@pages/candidates/actions/candidate.action-types';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';

export default {
  editCandidate: () => createAction(candidateActionTypes.editCandidate),
  openDocumentModal: () => createAction(candidateActionTypes.openDocumentModal),
  updateDocumentList: () => createAction(candidateActionTypes.updateDocumentList),
  updateDetail: (detail?: CandidateDetail) =>
    createAction(candidateActionTypes.updateDetail, detail),
  navigateBack: (navigateTo: string[]) =>
    createAction(candidateActionTypes.navigateBack, navigateTo),
  saveDocument: (formData: FormData) => createAction(candidateActionTypes.saveDocument, formData),
  candidateUpdated: () => createAction(candidateActionTypes.candidateUpdated),
};
