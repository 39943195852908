import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthService);

    const apiCall = request.urlWithParams.indexOf(environment.apiUrl) > -1;
    const isLogin = request.urlWithParams.indexOf('login') > -1;
    let clonedRequest = request.clone();

    if (environment.authStorageStrategy === 'localStorage' && apiCall && !isLogin) {
      const token = auth.getAuthToken();

      if (!token) {
        console.warn(
          `Auth token has falsy value: ${token}. Skipping attach Authorization header to the request`
        );
      } else {
        clonedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${auth.getAuthToken()}`,
          },
        });
      }
    }

    return next.handle(clonedRequest);
  }
}
