import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  modalTitle$: Observable<string>;
  iconUrl$: Observable<string>;

  constructor(private modalStateService: ModalStateService) {}

  ngOnInit(): void {
    this.modalTitle$ = this.modalStateService.select('modalTitle');
    this.iconUrl$ = this.modalStateService.select('iconUrl');
  }
}
