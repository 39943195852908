import { Component, Input } from '@angular/core';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-text-field-label',
  templateUrl: './text-field-label.component.html',
  styleUrls: ['./text-field-label.component.scss'],
})
export class TextFieldLabelComponent {
  @Input() isHintShown = true;
  @Input() hintText: string;
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() fieldId: string;
  hintType = HintType;
}
