<div class="user-profile-header d-flex justify-content-start align-items-center">
    <img [src]="(me$ | async)?.avatarThumb || 'assets/image/person-placeholder.svg'" alt="Profile image">
    <span class="user-name">
        {{(me$ | async)?.name}}
    </span>
    <nav class="d-inline-block">
        <a routerLink="/users" routerLinkActive="active-page" [routerLinkActiveOptions]="{exact: true}">
            {{'users.menu.personal_details' | translate}}
        </a>
        <a routerLink="/users/manage" routerLinkActive="active-page">
            {{'users.menu.manage_users' | translate}}
        </a>
    </nav>
</div>
