import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { historyActionTypes } from '@shared/modules/history-message/actions/history-message.action-types';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';

export default {
  clearInput: () => createAction(historyActionTypes.clearInput),
  loadMore: () => createAction(historyActionTypes.loadMore),
  editHistory: (history: HistoryMessage) => createAction(historyActionTypes.editHistory, history),
  deleteHistory: (history: HistoryMessage) =>
    createAction(historyActionTypes.deleteHistory, history),
  saveEditedHistory: (payload: { message: string; historyId: number }) =>
    createAction(historyActionTypes.saveEditedHistory, payload),
  saveNewHistory: (message: string) => createAction(historyActionTypes.saveNewHistory, message),
};
