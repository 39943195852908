import includes from 'lodash-es/includes';
import { UserRole } from '../classes/UserRole';
import { UserPermission } from '../classes/UserPermission';
import { UserRolePermission } from '../classes/UserRolePermission';

export class AuthUtils {
  static isContainUserRole(routeEnabledDisabledRoles: UserRole[], userRoles: UserRole[]): boolean {
    if (includes(userRoles, UserRole.SuperAdmin)) {
      return true;
    }

    return (routeEnabledDisabledRoles || []).some((role) => includes(userRoles, role));
  }

  static isContainUserPermission(
    routeEnabledDisabledPermissions: UserPermission[],
    userRoles: UserRole[]
  ): boolean {
    if (includes(userRoles, UserRole.SuperAdmin)) {
      return true;
    }

    return (routeEnabledDisabledPermissions || []).some((permission) => {
      return userRoles.some((userRole) => UserRolePermission[userRole].indexOf(permission) !== -1);
    });
  }
}
