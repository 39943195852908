import { Component, Input, OnInit } from '@angular/core';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { TippyPlacement } from '@shared/classes/TippyPlacement';
import { ValidationErrors } from '@angular/forms';
import includes from 'lodash-es/includes';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent implements OnInit {
  @Input() hintTypes: HintType[];
  @Input() placement: TippyPlacement = 'right-start';
  @Input() errors: ValidationErrors;
  @Input() hintText: string;
  isErrorHintShown = false;
  isInfoHintShown = false;

  ngOnInit(): void {
    this.isErrorHintShown = includes(this.hintTypes, HintType.Error);
    this.isInfoHintShown = includes(this.hintTypes, HintType.Info);
  }
}
