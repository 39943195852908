import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { AuthGuard } from '@shared/modules/auth/auth.guard';
import { UserRole } from '@shared/modules/auth/classes/UserRole';

const routes: Routes = [
  {
    path: 'notfound',
    pathMatch: 'full',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'NOT_FOUND.PAGE_TITLE',
      authGuardParams: {
        rolesDisabled: [UserRole.UnAuthorized],
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotFoundRoutingModule {}
