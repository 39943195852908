import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldModule } from '@shared/modules/text-field/text-field.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { ButtonModule } from '@shared/modules/button/button.module';
import { HintModule } from '@shared/modules/hint/hint.module';
import { PhotoUploadComponent } from './components/photo-upload/photo-upload.component';

@NgModule({
  declarations: [PhotoUploadComponent],
  exports: [PhotoUploadComponent],
  imports: [
    CommonModule,
    TextFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomPipesModule,
    FormComponentsModule,
    ButtonModule,
    HintModule,
  ],
})
export class PhotoUploadModule {}
