import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';

export interface HistoryMessageState {
  isSaveButtonDisabled: boolean;
  historyMessages: HistoryMessage[];
  allMessageCount: number;
  currentHistoryPage: number;
}

export const historyMessageInitialState: HistoryMessageState = {
  isSaveButtonDisabled: false,
  historyMessages: [],
  allMessageCount: 0,
  currentHistoryPage: 1,
};
