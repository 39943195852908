import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';

@Component({
  selector: 'app-switch-table-cell',
  templateUrl: './switch-table-cell.component.html',
  styleUrls: ['./switch-table-cell.component.scss'],
})
export class SwitchTableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<any>;
  statuses = ProjectStatus;
}
