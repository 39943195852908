export class EndpointsConfig {
  static login = '/auth/login/email';
  static logout = '/auth/logout';
  static resetPassword = '/auth/reset-password';
  static forgotPassword = '/auth/forgot-password';
  static me = '/users/me';
  static usersFilters = '/users/filters';
  static partnersFilters = '/partners/filters';
  static partnersFirstLetters = '/partners/first-letter';
  static users = '/users';
  static usersListByRole = '/users/list-by-roles';
  static usersCheckEmail = '/users/check';
  static countries = '/countries';
  static offices = '/offices';
  static partners = '/partners';
  static candidates = '/candidates';
  static candidatesFirstLetters = '/candidates/first-letter';
  static candidatesFilters = '/candidates/filters';
  static sources = '/sources';
  static jobTypes = '/job-types';
  static specializations = '/specializations';
  static languages = '/languages';
  static languageLevels = '/languages/levels';
  static documentTypes = '/candidates/document-types';
  static userAvatar = '/users/avatar';
  static employmentTypes = '/employment-types';
  static partnersDropdown = '/partners/dropdown';
  static positions = '/positions';
  static positionsFirstLetters = '/positions/first-letter';
  static positionsFilters = '/positions/filters';
  static educationTypes = '/education-types';
  static workExperiences = '/work-experiences';
  static boardColumns = '/positions/statuses';
  static tags = '/tags';
  static providerTypes = '/provider-types';
  static boardCandidates = (positionId: number) => `/positions/${positionId}/candidates`;
  static searchBoardCandidate = (positionId: number) =>
    `/positions/${positionId}/candidates/search`;
  static moveOrAddBoardCandidate = (positionId: number, candidateId: number) =>
    `/positions/${positionId}/candidates/${candidateId}`;
  static providers = (advertisementId: number) => `/advertisements/${advertisementId}/providers`;
  static providerById = (advertisementId: number, providerId: number) =>
    `/advertisements/${advertisementId}/providers/${providerId}`;
  static positionStatus = (positionId: number) => `/positions/${positionId}/status`;
  static providerStatus = (advertisementId: number, providerId: number) =>
    `/advertisements/${advertisementId}/providers/${providerId}/status`;
  static advertisement = (positionId: number) => `/positions/${positionId}/advertisements`;
  static advertisementById = (positionId: number, advertisementId: number) =>
    `/positions/${positionId}/advertisements/${advertisementId}`;
  static advertisementImage = (advertisementId: number) =>
    `/advertisements/${advertisementId}/documents`;
  static advertisementImageById = (advertisementId: number, imageId: number) =>
    `/advertisements/${advertisementId}/documents/${imageId}`;
  static positionById = (positionId: number) => `/positions/${positionId}`;
  static positionHistory = (positionId: number) => `/positions/${positionId}/history`;
  static positionHistoryWithId = (positionId: number, historyId: number) =>
    `/positions/${positionId}/history/${historyId}`;
  static downloadCandidateDocuments = (candidateId: number, documentId: number) =>
    `/candidates/${candidateId}/document/${documentId}`;
  static candidateHistory = (candidateId: number) => `/candidates/${candidateId}/history`;
  static candidateHistoryWithId = (candidateId: number, historyId: number) =>
    `/candidates/${candidateId}/history/${historyId}`;
  static candidateDocuments = (candidateId: number) => `/candidates/${candidateId}/document`;
  static candidatesById = (candidateId: number) => `/candidates/${candidateId}`;
  static citySearch = (countryId: number) => `/countries/${countryId}/cities/search`;
  static partnerById = (partnerId: number) => `/partners/${partnerId}`;
  static partnerHistory = (partnerId: number) => `/partners/${partnerId}/history`;
  static projects = (partnerId: number) => `/partners/${partnerId}/projects`;
  static projectById = (partnerId: number, projectId: number) =>
    `/partners/${partnerId}/projects/${projectId}`;
  static projectHistory = (projectId: number) => `/projects/${projectId}/history`;
  static projectPositions = (projectId: number) => `/projects/${projectId}/positions`;
  static partnerHistoryById = (partnerId: number, historyId: number) =>
    `/partners/${partnerId}/history/${historyId}`;
  static projectHistoryById = (projectId: number, historyId: number) =>
    `/projects/${projectId}/history/${historyId}`;
  static projectStatus = (partnerId: number, projectId: number) =>
    `/partners/${partnerId}/projects/${projectId}/status`;
}
