import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AUTH_STORAGE } from '@shared/modules/auth/providers/auth-storage.injection-token';
import { IAuthStorageStrategy } from '@shared/modules/auth/classes/IAuthStorageStrategy';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtToken } from '@shared/classes/jwtToken';
import { EndpointsConfig } from '@config/endpoints.config';
import { AuthRequestDto } from '@shared/modules/auth/classes/AuthRequestDto';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { SetFirstPasswordDto } from '@shared/modules/auth/classes/SetFirstPasswordDto';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { Location } from '@angular/common';
import { UserService } from '@pages/users/services/user.service';
import { HttpService } from '../../http/http.service';
import { User } from '../classes/User';
import { UserRole } from '../classes/UserRole';
import { AuthGuardParams } from '../classes/AuthGuardParams';

/** Write/read data of user's to/from local storage, */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInUser: User;
  loggedInUserChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  previousURL: string;

  readonly authStorage: IAuthStorageStrategy;
  private readonly jwtHelper: JwtHelperService;
  private readonly router: Router;

  constructor(private httpService: HttpService, private injector: Injector) {
    this.authStorage = injector.get<IAuthStorageStrategy>(AUTH_STORAGE);
    this.jwtHelper = injector.get<JwtHelperService>(JwtHelperService);
    this.router = injector.get<Router>(Router);
  }

  setUserAndToken(token, userData) {
    this.setUser(userData);
  }

  redirectToPreviousUrlOrHome() {
    if (this.previousURL) {
      const splitUrl = this.previousURL.split('?');
      const route = splitUrl[0];
      const { queryParams } = this.router.parseUrl(this.previousURL);
      this.router.navigate([route], { queryParams });
      this.previousURL = null;
    } else {
      this.router.navigate([AppConfig.homeUrl]);
    }
  }

  logout(doRedirect?: boolean) {
    this.authStorage.removeAll();

    if (this.loggedInUser) {
      this.setUser(null);
    }

    if (doRedirect) {
      this.router.navigate([AppConfig.loginUrl]);
    }
  }

  login(payload: AuthRequestDto): Observable<User> {
    return this.httpService.post(EndpointsConfig.login, payload).pipe(
      concatMap(() => this.loadUser()),
      tap((user: User) => {
        this.setUser(user);
        this.redirectToPreviousUrlOrHome();
      })
    );
  }

  logoutWithApi() {
    return this.httpService.post(EndpointsConfig.logout, null).pipe(tap(() => this.logout(true)));
  }

  resetPassword(
    payload: SetFirstPasswordDto | { email: string },
    isSetFirstPasswordMode: boolean
  ): Observable<unknown> {
    const toastService = this.injector.get<ToastService>(ToastService);

    const endpoint = isSetFirstPasswordMode
      ? EndpointsConfig.resetPassword
      : EndpointsConfig.forgotPassword;

    const message = isSetFirstPasswordMode
      ? 'auth_forms.reset_password_request'
      : 'auth_forms.send_email';

    return this.httpService.post(endpoint, payload).pipe(
      tap(() => {
        toastService.showSuccess(getGeneralMessage(message, true));
        this.router.navigate([AppConfig.loginUrl]);
      }),
      catchError((err) => {
        toastService.showError(getGeneralMessage(message, false));
        return of(err);
      })
    );
  }

  loadUser(): Observable<User> {
    const userService = this.injector.get<UserService>(UserService);
    const toastService = this.injector.get<ToastService>(ToastService);

    return this.httpService.get(EndpointsConfig.me).pipe(
      tap((me: User) => {
        userService.setState({ me });
      }),
      catchError((err) => {
        toastService.showError('auth_forms.load_user_error');
        this.logout(true);
        return throwError(err);
      })
    );
  }

  checkAuth(): Promise<boolean> {
    console.log('check auth-user!');

    const csrfToken = this.authStorage.readCSRFToken();

    if (!csrfToken) {
      return Promise.resolve(true);
    }

    return new Promise((resolve) => {
      this.loadUser().subscribe(
        (userData: User) => {
          const location = this.injector.get(Location);
          this.setUser(userData);
          if (location.path().includes(AppConfig.loginUrl)) {
            this.redirectToPreviousUrlOrHome();
          }
          resolve(true);
        },
        () => {
          this.logout();
          resolve(true);
        }
      );
    });
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    let route;
    const userRoles = this.getUserRoles();

    if (authGuardParams.redirectTo) {
      route = authGuardParams.redirectTo;
    } else if (userRoles?.length > 0 && userRoles[0] !== UserRole.UnAuthorized) {
      route = AppConfig.homeUrl;

      // extend navigate logic by role
      // if (userRole === UserRole.ADMIN) {
      //   route = '/admin-home';
      // }
    } else {
      route = AppConfig.loginUrl;
    }

    this.previousURL = previousUrl;
    this.router.navigate([route]);
  }

  private checkJWTAuthToken(token): JwtToken | null {
    const jwtToken = this.getDecodedToken(token);
    if (!jwtToken || !jwtToken.sub || !jwtToken.exp) {
      return null;
    }
    if (this.jwtHelper.isTokenExpired(token, 60 * 30)) {
      console.log('TODO: JWT TOKEN EXPIRED');
      return null;
    }

    return jwtToken;
  }

  private getDecodedToken(token: string): JwtToken {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (error) {
      console.log('error while parsing token: ', error);
      return null;
    }
  }

  private setUser(userData: User | null) {
    if (userData) {
      this.loggedInUser = userData;
    } else {
      this.loggedInUser = null;
    }
    this.loggedInUserChange.next(this.loggedInUser);
  }

  getLoggedInUser(): User {
    return this.loggedInUser;
  }

  isLoggedIn(): boolean {
    return !!this.loggedInUser;
  }

  getAuthToken(): string {
    const authToken = this.authStorage.readAuthToken();
    if (this.checkJWTAuthToken(authToken)) {
      return authToken;
    }

    return null;
  }

  getUserRoles(): UserRole[] {
    let roles = [UserRole.UnAuthorized];

    if (Array.isArray(this.loggedInUser?.roles)) {
      roles = this.loggedInUser?.roles.map((userRole) => userRole.name);
    }

    return roles;
  }
}
