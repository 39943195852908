import { PositionCardTableRow } from '@pages/partners/classes/PositionCardTableRow';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';
import { head, isEmpty } from 'lodash-es';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';

export function mapPositionToTableRow(position: ProjectPosition): PositionCardTableRow {
  return {
    id: position.id,
    projectManager: head(position.projectManagers)?.name,
    name: position.name,
    nameIconUrl: `${environment.assetUrl}/${head(position.specializations)?.icon}`,
    status: position.status,
    code: position.code,
    date: !isEmpty(position.createdAt)
      ? moment(position.createdAt).format(AppConstants.dateFormat)
      : '',
  };
}
