import { Injectable } from '@angular/core';
import { COOKIE_ACCEPTED_NAME } from '@config/app.constant';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { MatModalService } from '../mat-modal/mat-modal.service';

@Injectable({
  providedIn: 'root',
})
export class CookiePopupService {
  // make variable available in whole app
  cookieAccepted: boolean;
  isModalOpened = false;

  constructor(private authService: AuthService, private matModalService: MatModalService) {
    const storedValue = localStorage.getItem(COOKIE_ACCEPTED_NAME);

    this.cookieAccepted = storedValue !== null ? storedValue === 'true' : null;
  }

  openDialog(disableBackdrop = false): void {
    if (!this.isModalOpened) {
      this.isModalOpened = true;

      this.matModalService
        .openDialog({
          position: {
            top: '0',
          },
          width: '100%',
          maxWidth: '100%',
          panelClass: 'cookie-popup',
          disableClose: true,
          hasBackdrop: !disableBackdrop,
        })
        .subscribe((accepted) => {
          const acceptedStr = accepted ? 'true' : 'false';

          localStorage.setItem(COOKIE_ACCEPTED_NAME, acceptedStr);
          this.cookieAccepted = accepted;

          if (accepted && this.authService.previousURL) {
            this.authService.redirectToPreviousUrlOrHome();
          }

          this.isModalOpened = false;
        });
    }
  }
}
