import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../../base-component/base.component';
import { ButtonClass } from './ButtonClass';

@UntilDestroy()
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent {
  @Input() text = 'Button';
  @Input() icon = '';
  @Input() disabled: boolean;
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() color: ThemePalette;
  @Input() buttonClass: ButtonClass = ButtonClass.PRIMARY;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
