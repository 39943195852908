import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { AppConstants } from '@config/app.constant';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

export class CustomMomentAdapter extends MomentDateAdapter {
  format(date: Moment): string {
    return super.format(date, AppConstants.dateFormat);
  }
}

export const datePickerProviders = [
  { provide: MAT_DATE_LOCALE, useValue: 'hu_HU' },
  {
    provide: DateAdapter,
    useClass: CustomMomentAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
];
