import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { partnerActionTypes } from '@pages/partners/actions/partner.action-types';
import { ProjectCardTableRow } from '@pages/partners/classes/ProjectCardTableRow';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';

export default {
  editPartner: () => createAction(partnerActionTypes.editPartner),
  editProject: () => createAction(partnerActionTypes.editProject),
  createProject: () => createAction(partnerActionTypes.createProject),
  navigateBack: () => createAction(partnerActionTypes.navigateBack),
  toggleProjectStatus: () => createAction(partnerActionTypes.toggleProjectStatus),
  navigateToProject: (project?: ProjectCardTableRow) =>
    createAction(partnerActionTypes.navigateToProject, project),
  deleteProject: (project?: ProjectCardTableRow) =>
    createAction(partnerActionTypes.deleteProject, project),
  saveAsPartnerFilter: () => createAction(partnerActionTypes.saveAsPartnerFilter),
  deletePartnerFilter: () => createAction(partnerActionTypes.deletePartnerFilter),
  projectUpdated: (project: ProjectDetail) =>
    createAction(partnerActionTypes.projectUpdated, project),
  partnerUpdated: (partner: PartnerDetail) =>
    createAction(partnerActionTypes.partnerUpdated, partner),
  openModalToDeletePosition: (position?: ProjectPosition) =>
    createAction(partnerActionTypes.openModalToDeletePosition, position),
  deletePosition: (position: ProjectPosition) =>
    createAction(partnerActionTypes.deletePosition, position),
  togglePositionStatus: (position: ProjectPosition) =>
    createAction(partnerActionTypes.togglePositionStatus, position),
};
