import { filter } from 'rxjs/operators';
import { MonoTypeOperatorFunction } from 'rxjs';
import { Action } from '../classes/Action';

export function ofType(type: string | string[]): MonoTypeOperatorFunction<Action> {
  let types: string[] = [];
  types = Array.isArray(type) ? type : [type];

  return filter((action: Action) => {
    if (action === undefined || action?.type?.length === 0) {
      return false;
    }

    return types.some((actionType) => action.type === actionType);
  });
}
