/** Main constants should store here */
export class AppConstants {
  static test = 'test';
  static LOADER_TIMEOUT = 1000;
  static lightBlueBackgroundColor = '#F7F9FB';
  static defaultBackgroundColor = '#fff';
  static dotMenuWidth = 210; // px
  static inputFieldFormatKey = 'format';
  static backendDateFormat = 'YYYY-MM-DD';
  static dateFormat = 'YYYY.MM.DD.';
  static dateTimeFormat = 'YYYY.MM.DD. HH:mm';
  static minInputLength = 3;
  static maxInputLength = 255;
  static cityNameMinInputLength = 2;
  static fullHeightModalWidth = '680px';
  static historyMessagesPerPage = 5;
  static cardTableRowsPerPage = 4;
  static tableRowsPerPage = 25;
  static tableHeaderHeight = 34;
  static tableRowHeight = 34;
  static historyMessageMaxLength = 500;
  static historyMessageMinLength = 1;
  static maxFileSizeBytes = 5 * 1000 * 1000; // ~5MB
  static supportedImageExtensions = ['png', 'jpg', 'jpeg'];
  static documentTypesWithExpiryDate = [
    'medicalCertificate',
    'lungFilter',
    'privacyStatement',
    'parentStatement',
  ];
  static advertisementFreeTextMaxLength = 65535;
  static advertisementProvidersPerPage = 1000;
  static dragTableBorderColors = {
    5: '#22BC44',
    6: '#D41F26',
  };

  // regex
  static emailRegex = /^[\w\d]+[-+._]{0,1}[\w\d+]+@([\w-]+\.)+[\w-]{2,4}$/;
  static passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\w@#$%&*+:;!.?()-]{8,}$/;
  static taxNumberRegex = /(^([0-9]{8}-[0-9]{1}-[0-9]{2})$)|(^([0-9]{9})$)|(^([A-Z]{3})([0-9]{8})$)/;
  static zipCodeRegex = /^([0-9]{4,5})$/;
  static phoneRegex = /^[+][0-9]{1,3} [0-9]{8,9}$/;
  static phoneRegexStartsWith = /^[+][0-9]{1,3}/;
  static projectCode = /^[A-Z]{3}[A-Z0-9]{1}$/;
  static omIdentifierRegex = /^[7]{1}[0-9]{10}$/;
}

export const ROUTER_TRANSITION_TIMING = '.5s cubic-bezier(0.77, 0, 0.175, 1)';
export enum PageName {
  Home = 'Home',
  Users = 'Users',
  FormFields = 'FormFields',
}

export const COOKIE_ACCEPTED_NAME = 'cookie-accepted';

export enum ApiTypeParams {
  PARTNER = 'PARTNER',
  CANDIDATE = 'CANDIDATE',
  POSITION = 'POSITION',
}
