import { Component, TemplateRef, ViewChild } from '@angular/core';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';

@Component({
  selector: 'app-card-filtering-option',
  templateUrl: './card-filtering-option.component.html',
  styleUrls: ['./card-filtering-option.component.scss'],
})
export class CardFilteringOptionComponent implements INgSelectTemplate {
  @ViewChild(TemplateRef, { static: true, read: TemplateRef })
  templateRef: TemplateRef<HTMLElement>;
}
