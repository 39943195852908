import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';

export default {
  filterByLetterAction: (letter_filter: string) =>
    createAction(filterActionTypes.filterByLetter, { letter_filter }),
  resetFiltersAction: () => createAction(filterActionTypes.resetFilters),
  manageFilterAction: (isEditMode: boolean) =>
    createAction(filterActionTypes.manageFilter, isEditMode),
  deleteFilterAction: () => createAction(filterActionTypes.deleteFilter),
};
