<ng-container>
    <div *ngFor="let key of errorKeys" class="error-item">
        <ng-container *ngIf="key === formatKey; else plainTextError">
            {{('validation_errors.' + key) | appTranslateWithParams : {controlName: formatKeyToTranslate} | async }}
        </ng-container>
        <ng-template #plainTextError>
            {{('validation_errors.' + key) | translate}}
        </ng-template>
    </div>
</ng-container>
