import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '../classes/UserRole';
import { AuthUtils } from '../utils/auth.utils';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appUserHasRole], [appUserExceptRole]',
})
export class UserHasRoleDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService
  ) {}

  @Input() set appUserHasRole(userRoles: UserRole[]) {
    if (AuthUtils.isContainUserRole(userRoles, this.auth.getUserRoles())) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set appUserExceptRole(userRoles: UserRole[]) {
    if (!AuthUtils.isContainUserRole(userRoles, this.auth.getUserRoles())) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
