import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { userInitialState, UserState } from '@shared/modules/event-bus/state/users/user.state';
import { merge, Observable, of } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { userActionTypes } from '@pages/users/actions/user.action-types';
import { catchError, finalize, mergeAll, switchMap, tap } from 'rxjs/operators';
import { UserApiService } from '@pages/users/services/user-api.service';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ComponentType } from '@angular/cdk/overlay';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { AvatarUploadResponse } from '@pages/users/classes/AvatarUploadResponse';
import modalActions from '../../../shared/modules/mat-modal/actions/modal.actions';

@Injectable({
  providedIn: 'root',
})
export class UserService extends EventBusService<UserState> {
  constructor(
    private userApiService: UserApiService,
    private modalStateService: ModalStateService,
    private modalService: MatModalService,
    private toast: ToastService
  ) {
    super(userInitialState);
  }

  getMe(): Observable<User> {
    return merge(
      of(this.eventBus.on(userActionTypes.getMe), this.eventBus.on(userActionTypes.refreshMe))
    ).pipe(
      mergeAll(),
      switchMap(() => {
        return this.userApiService.getMe().pipe(
          tap((me: User) => {
            this.setState({ me });
          }),
          catchError((err) => of(err))
        );
      })
    );
  }

  openAvatarUploadModal(contentComponent: ComponentType<any>): Observable<any> {
    return this.eventBus.on(userActionTypes.openAvatarUploadModal).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'users.upload_avatar',
        });

        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });
      })
    );
  }

  uploadAvatar(): Observable<AvatarUploadResponse> {
    return this.eventBus.on(userActionTypes.uploadAvatar).pipe(
      switchMap(({ avatarBase64 }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.userApiService
          .uploadUserAvatar({
            avatar: avatarBase64,
          })
          .pipe(
            tap((response: AvatarUploadResponse) => {
              this.refreshAvatarState(response?.url);
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            }),
            catchError((err) => {
              this.toast.showError(getGeneralMessage('users.upload_message', false));
              return of(err);
            }),
            finalize(() => {
              this.modalStateService.setState({ isSaveButtonDisabled: false });
            })
          );
      })
    );
  }

  private refreshAvatarState(newAvatarUrl: string): void {
    if (newAvatarUrl) {
      const { me } = this.getStateSnapshot();
      this.setState({ me: { ...me, avatarThumb: newAvatarUrl } });
    }
  }
}
