export const sideNavigationConfig = [
  {
    icon: 'assets/image/common/whc-icon.svg',
    navigateTo: null,
  },
  {
    icon: 'assets/image/common/partner-icon.svg',
    navigateTo: 'partners',
  },
  {
    icon: 'assets/image/common/position-icon.svg',
    navigateTo: 'positions',
  },
  {
    icon: 'assets/image/common/applicant-icon.svg',
    navigateTo: 'candidates',
  },
];
