import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import keys from 'lodash-es/keys';
import includes from 'lodash-es/includes';
import get from 'lodash-es/get';
import { AppConstants } from '@config/app.constant';
import { camelToSnakeCase } from '@shared/utils/camel-to-snake.util';

@Component({
  selector: 'app-hint-error-list',
  templateUrl: './hint-error-list.component.html',
  styleUrls: ['./hint-error-list.component.scss'],
})
export class HintErrorListComponent implements OnInit {
  @Input() errors: ValidationErrors;
  errorKeys: string[];
  formatKey = AppConstants.inputFieldFormatKey;
  formatKeyToTranslate: string;

  ngOnInit() {
    this.errorKeys = keys(this.errors).map((key) => camelToSnakeCase(key));
    if (includes(this.errorKeys, this.formatKey)) {
      this.formatKeyToTranslate = get(this.errors.format, keys(this.errors.format)[0], '');
    }
  }
}
