import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from '../button-base.components';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent extends ButtonBaseComponent {
  @Input() color: 'basic' | 'warn' = 'basic';
  @Input() buttonClass: string;
  @Input() buttonStyle: 'filled' | 'outline' | 'flat' = 'filled';
}
