import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TippyModule } from '@ngneat/helipopper';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { AuthModule } from '@shared/modules/auth/auth.module';
import { DotMenuComponent } from './components/dot-menu/dot-menu.component';

@NgModule({
  declarations: [DotMenuComponent],
  exports: [DotMenuComponent],
  imports: [
    CommonModule,
    TippyModule,
    InlineSVGModule,
    TranslateModule,
    CustomPipesModule,
    AuthModule,
  ],
})
export class DotMenuModule {}
