import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-version-display',
  templateUrl: './version-display.component.html',
  styleUrls: ['./version-display.component.scss'],
})
export class VersionDisplayComponent {
  getVersion() {
    return `${environment.name} - v${version}`;
  }
}
