import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';

@Pipe({
  name: 'appTimeago',
})
export class TimeagoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(dateTime: string): Observable<string> {
    return interval(60 * 1000).pipe(
      startWith(dateTime),
      map(() => this.getValue(dateTime))
    );
  }

  private getValue(dateTime: string): string {
    const now = moment().utc();
    const createdDate = moment(dateTime).utc(true);
    const duration = moment.duration(now.diff(createdDate));
    const nowString = this.translate.instant('common.now');

    if (duration.asSeconds() < 60) {
      return nowString;
    }

    const timeBoundaries = [
      {
        duration: duration.asMinutes(),
        boundary: 60,
        textValue: this.translate.instant('common.minute_ago'),
      },
      {
        duration: duration.asHours(),
        boundary: 24,
        textValue: this.translate.instant('common.hours_ago'),
      },
      {
        duration: duration.asDays(),
        boundary: 7,
        textValue: this.translate.instant('common.days_ago'),
      },
    ];

    const foundBoundary = timeBoundaries.find((data) => data.duration < data.boundary);

    if (foundBoundary) {
      return `${Math.floor(foundBoundary.duration)} ${foundBoundary.textValue}`;
    }

    return createdDate.format(AppConstants.dateTimeFormat);
  }
}
