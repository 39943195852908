import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';
import { FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends FormInputBaseComponent implements OnInit {
  @Input() placeholder = 'common.select_file';
  fileNameControl = new FormControl();

  constructor(@Self() @Optional() private ngControl: NgControl) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }

  onFileChange(event): void {
    if (event.target?.files?.length > 0) {
      const file: File = event.target.files[0];

      // TODO validations
      this.control.setValue(file);
      this.fileNameControl.setValue(file.name);
    }
  }
}
