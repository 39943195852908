import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-section-header',
  templateUrl: './modal-section-header.component.html',
  styleUrls: ['./modal-section-header.component.scss'],
})
export class ModalSectionHeaderComponent {
  @Input() sectionTitle: string;
}
