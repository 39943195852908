import { Directive, Inject, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { startWith, tap } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appSetLanguage]',
})
export class SetLanguageDirective implements OnInit {
  private htmlElement: HTMLElement;

  constructor(
    private translate: TranslateService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.htmlElement = this.document.querySelector('html');
    if (this.htmlElement) {
      this.translate.onLangChange
        .pipe(
          startWith({
            lang: this.translate.defaultLang,
          }),
          tap(({ lang }: LangChangeEvent) => {
            this.renderer.setAttribute(this.htmlElement, 'lang', lang);
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }
}
