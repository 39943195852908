export const positionActionTypes = {
  editPosition: '[Position detail] - edit position',
  createPosition: '[Positions] - create position',
  updatePosition: '[Positions] - update position',
  refreshDetails: '[Position detail] - refresh/rerender position details',
  refreshAdvertisementDetail: '[Advertisement detail] - refresh/rerender Advertisement details',
  editAdvertisement: '[Advertisement detail] - edit advertisement',
  updateAdvertisement: '[Advertisement detail] - update advertisement',
  openAddProvider: '[Advertisement detail] - open add provider modal',
  createProvider: '[Advertisement detail] - add/create provider',
  updateProvider: '[Advertisement detail] - update existing provider',
  openModalToUpdateProvider: '[Advertisement detail] - open modal to update existing provider',
  updateProviderStatus: '[Advertisement detail] - update existing provider status',
  refreshProviderList: '[Advertisement detail] - refresh provider list',
  deleteProvider: '[Advertisement detail] - delete provider',
  uploadImage: '[Advertisement detail] - upload image',
  deleteImage: '[Advertisement detail] - delete image',
  openModalToUploadImage: '[Advertisement detail] - open modal to upload image',
  refreshImageList: '[Advertisement detail] - refresh images',
  openModalToSearchCandidate: '[Positions board] - open modal to search candidate',
  openModalToRejectCandidate: '[Positions board] - open modal to reject candidate',
  updateCandidatePosition: '[Positions board] - update candidate position on board',
  restoreBoardToPreviousState: '[Positions board] - restore board to previous state',
  confirmRejectedCandidate: '[Positions board] - confirm rejected candidate',
  dismissRejectCandidate: '[Positions board] - dismiss reject candidate modal',
  addCandidateToPosition: '[Positions board] - add candidate to a position',
  triggerBoardRefresh: '[Positions board] - trigger candidate board refresh',
  navigateToCandidateDetail: '[Positions board] - navigate to candidate detail',
};
