import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { CustomCell } from '@shared/modules/table/classes/CustomCell';
import { ICustomHeaderComponent } from '@shared/modules/table/classes/ICustomHeaderComponent';

@Directive({
  selector: '[appCustomHeader]',
})
export class CustomHeaderDirective implements OnInit {
  headerName: string;
  headerTemplate: TemplateRef<any>;
  component: ComponentType<any>;

  @Input() set appCustomHeader({ name, component }: CustomCell<ICustomHeaderComponent>) {
    this.headerName = name;
    this.component = component;
  }
  constructor(
    private templateRef: TemplateRef<ICustomHeaderComponent>,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const componentFactory = this.cfr.resolveComponentFactory(this.component);
    const component = this.vcr.createComponent<ICustomHeaderComponent>(componentFactory);
    this.headerTemplate = component.instance.headerTemplate;
  }
}
