import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';

@Injectable({
  providedIn: 'root',
})
export class TableService extends EventBusService<{}> {
  constructor() {
    super({});
  }
}
