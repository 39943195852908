import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from '@shared/modules/button/button-base.components';

@Component({
  selector: 'app-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss'],
})
export class RoundedButtonComponent extends ButtonBaseComponent {
  @Input() isActive = false;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
