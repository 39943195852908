import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INgSelectTemplate } from '@shared/modules/form-components/select/classes/INgSelectTemplate';
import { Subject } from 'rxjs';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { FormInputBaseComponent } from '../../text-field/form-input-base.component';

@UntilDestroy()
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends FormInputBaseComponent implements OnInit, OnDestroy {
  @ContentChild('labelTemplate', { static: true })
  labelTemplateComponent: INgSelectTemplate;
  @ContentChild('optionsTemplate', { static: true })
  optionsTemplateComponent: INgSelectTemplate;
  @ContentChild('footerTemplate', { static: true })
  footerTemplateComponent: INgSelectTemplate;
  footerTemplate: TemplateRef<INgSelectTemplate>;
  labelTemplate: TemplateRef<INgSelectTemplate>;
  optionsTemplate: TemplateRef<INgSelectTemplate>;
  @ViewChild('select') selectComponent: NgSelectComponent;
  @Input() options: any[];
  @Input() searchable = false;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() bindLabel = 'label';
  @Input() bindValue = 'value';
  @Input() bindValueByPropertyNameToControl: string;
  @Input() notFoundText = 'common.not_found';
  @Input() customClass = 'custom-ng-select';
  @Input() hideSelected = false;
  @Input() closeOnSelect = true;
  @Input() typeaheadText = 'common.typeahead_text';
  @Input() addTagText = 'common.add_new';
  @Input() isHintShown = true;
  @Input() hintText: string;
  @Input() useTypeahead = false;
  @Input() placeholder = 'common.please_select';
  @Input() addTagFn: Function;
  @Input() dropdownPosition: DropdownPosition = 'bottom';
  @Input() searchFn;
  @Input() appendTo: string;
  @Output() typeaheadChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() opened = new EventEmitter<void>();
  @Output() clicked = new EventEmitter<any>();
  typeahead$ = new Subject<string>();
  searchResults: {
    term: string;
    items: any[];
  };

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.labelTemplate = this.labelTemplateComponent?.templateRef;
    this.optionsTemplate = this.optionsTemplateComponent?.templateRef;
    this.footerTemplate = this.footerTemplateComponent?.templateRef;

    this.typeahead$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.typeaheadChange.emit(value));

    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;

    if (this.bindValueByPropertyNameToControl) {
      this.onChange = this.customPropertyBind.bind(this);
    }
    return superCalled;
  }

  ngOnDestroy(skip?: boolean) {
    this.typeahead$.complete();
    return super.ngOnDestroy(skip);
  }

  onSearchChange(event) {
    this.searchResults = event;
  }

  onDropdownClose() {
    this.searchResults = null;
  }

  private customPropertyBind(value: any): void {
    if (!value) {
      this.value = null;
      this.control.setValue(null);
      return;
    }

    if (!value || value[this.bindValueByPropertyNameToControl] === undefined) {
      console.warn(`Value to bind with ${this.bindValueByPropertyNameToControl} is undefined!`);
      return;
    }
    this.control.setValue(value[this.bindValueByPropertyNameToControl]);
    this.value = value[this.bindValue];
  }
}
