import { ToastOptions } from '@ngneat/hot-toast/lib/hot-toast.model';
import { ToastType } from '@shared/modules/toast/classes/ToastType';

export const defaultToastConfig: ToastOptions = {
  style: {
    maxWidth: '485px',
    minWidth: '350px',
  },
  duration: 3000,
  position: 'top-right',
  className: 'custom-toast-container',
};

export const toastTypeIconMap = {
  [ToastType.Error]: 'assets/image/alert-icon.svg',
  [ToastType.Success]: 'assets/image/success-icon.svg',
};
