import { tap } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { AppConstants } from '@app/config/app.constant';
import { AppConfig } from '@config/app.config';
import { AuthService } from '../auth/services/auth.service';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { version } from '../../../../../package.json';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  constructor(
    private loader: GlobalLoaderService,
    private injector: Injector,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestClone = req.clone();
    const apiCall = requestClone.urlWithParams.includes(environment.apiUrl);
    if (apiCall) {
      requestClone = this.setCommonHeaders(requestClone);
    }

    return next.handle(requestClone).pipe(
      tap(
        () => {
          // handle succesful calls
          this.hideLoader(apiCall);
        },
        (err) => {
          this.hideLoader(apiCall);
          if (err instanceof HttpErrorResponse) {
            this.handleError(err);
          }
        }
      )
    );
  }

  setCommonHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const auth = this.injector.get(AuthService);

    let headers: { [key: string]: string } = {
      'Accept-Language': this.translate.currentLang || AppConfig.defaultLanguage,
      'x-client-version': version,
      'x-client-env': environment.name,
      'x-request-id': uuidv4(),
    };

    const CSRFToken = auth.authStorage.readCSRFToken();

    if (environment.authStorageStrategy === 'cookie' && CSRFToken) {
      headers = { ...headers, 'x-csrf-token': auth.authStorage.readCSRFToken() };
    }

    return req.clone({
      setHeaders: headers,
      withCredentials: true,
    });
  }

  hideLoader(apiCall) {
    if (apiCall) {
      setTimeout(() => {
        this.loader.hide();
      }, AppConstants.LOADER_TIMEOUT);
    }
  }

  handleError(err) {
    if (err.status === 401) {
      const auth = this.injector.get(AuthService);
      console.log('401 Error');
      auth.logout();
    } else {
      console.log('Other Error');
    }
  }
}
