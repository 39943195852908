import { HttpClient } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

class HttpLoaderResource implements ITranslationResource {
  suffix = '.json';
  prefix: string;

  constructor(location) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

/** get translate files */
export function HttpMultiLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('auth-forms'),
    new HttpLoaderResource('common'),
    new HttpLoaderResource('not-found'),
    new HttpLoaderResource('browser-not-supported'),
    new HttpLoaderResource('cookie-popup'),
    new HttpLoaderResource('form-components'),
    new HttpLoaderResource('page-header'),
    new HttpLoaderResource('side-filter'),
    new HttpLoaderResource('partners'),
    new HttpLoaderResource('positions'),
    new HttpLoaderResource('validation-errors'),
    new HttpLoaderResource('candidates'),
    new HttpLoaderResource('users'),
  ]);
}
