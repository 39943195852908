import { CookieService } from 'ngx-cookie-service';
import { IAuthStorageStrategy } from '@shared/modules/auth/classes/IAuthStorageStrategy';
import { environment } from '@environments/environment';
import { CookieAuthStorageService } from '@shared/modules/auth/services/cookie-auth-storage.service';

export function authStorageFactory(cookieService: CookieService): IAuthStorageStrategy {
  let authStorageStrategy: IAuthStorageStrategy;
  if (environment.authStorageStrategy === 'cookie') {
    authStorageStrategy = new CookieAuthStorageService(cookieService);
  } else {
    console.error('No auth storage implementation found!');
  }

  return authStorageStrategy;
}
