<div class="whc-form-field has-icon w-100 d-flex justify-content-center align-items-center"
  [class.field-disabled]="control.disabled">
    <input matInput readonly [matDatepicker]="picker" [max]="maxDate" [min]="minDate" [formControl]="control" [placeholder]="placeholder | translate">
    <mat-datepicker #picker (opened)="onCalendarOpened()"
      [yPosition]="yPosition"
      [xPosition]="xPosition"
      panelClass="custom-date-picker">
    </mat-datepicker>
    <span class="d-flex justify-content-end align-items-center">
        <app-icon-button *ngIf="clearable && control.value" class="mr-10px" textAlign="right" (buttonClick)="onResetDate()"
          iconUrl="assets/image/close-icon.svg">
        </app-icon-button>
        <app-icon-button textAlign="right" (buttonClick)="picker.open()"
          iconUrl="assets/image/calendar.svg">
        </app-icon-button>
    </span>
</div>
