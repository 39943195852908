<div class="position-detail-header h-100 d-flex align-items-center">
    <app-back-arrow-button class="back-button d-block" (buttonClick)="onNavigation()"></app-back-arrow-button>
    <ng-container *ngIf="isDetailLoaded$ | async">
        <div class="logo mr-10px" appSetBackgroundImage="https://source.unsplash.com/100x100"></div>

        <div class="name">{{detail?.name}}</div>

        <div class="pages">
            <button *ngFor="let page of pages" type="button" [class.active-page]="page.isActive" [routerLink]="page.routerLink">
                <span>{{page.text | translate}}</span>
            </button>
        </div>
    </ng-container>
</div>
