import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { ApiTypeParams } from '@config/app.constant';
import { map, switchMap, tap } from 'rxjs/operators';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';
import { UserFilterApiService } from '@shared/services/user-filter-api.service';
import { FormControl } from '@angular/forms';
import { FilteringManageService } from '@shared/modules/filtering/services/filtering-manage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';
import { FilterModalComponent } from '@shared/modules/mat-modal/components/filter-modal/filter-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ConfirmModalComponent } from '@shared/modules/mat-modal/components/confirm-modal/confirm-modal.component';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { MenuService } from '@shared/services/menu.service';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import { FilterTypes } from '@shared/modules/filtering/classes/FilterTypes';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';

enum SuperRequired {
  ON_INIT = 'OnInit',
  ON_DESTROY = 'OnDestroy',
}

@UntilDestroy()
@Component({
  template: '',
})
export class FilterContainerBaseComponent implements OnInit, OnDestroy {
  savedFilters$ = new BehaviorSubject<GetUsersFilter[]>([]);
  filter = new FormControl();
  filters$ = new BehaviorSubject<CardFilterInterface[]>([]);
  numberOfActiveFilter: number;

  saveMenuConfig: DotMenuItem = {
    id: 'save',
    textValue: 'common.save',
    actionOnClick: filterActions.manageFilterAction(true),
    permissions: [], // TODO add permissions
  };
  saveAsMenuConfig: DotMenuItem = {
    id: 'save_as',
    textValue: 'common.save_as',
    actionOnClick: filterActions.manageFilterAction(false),
    permissions: [], // TODO add permissions
  };
  deleteMenuConfig: DotMenuItem = {
    id: 'delete',
    textValue: 'common.delete',
    actionOnClick: filterActions.deleteFilterAction(),
    permissions: [], // TODO add permissions
  };

  menuConfig = new BehaviorSubject<DotMenuItem[]>([]);
  filterTypes = FilterTypes;
  encodedFilter: string;

  constructor(
    readonly filteringService: FilteringService,
    readonly userApiService: UserFilterApiService,
    readonly filteringManageService: FilteringManageService,
    readonly menuService: MenuService,
    readonly modalStateService: ModalStateService,
    readonly modalService: MatModalService
  ) {}

  ngOnInit(skip?: boolean): SuperRequired.ON_INIT {
    this.listenConfirmDeleteFilterModal();
    this.listenDeleteFilterAction();
    this.listenSavedFilterChange();
    this.listenFilterOptionsChange();
    if (!skip) {
      // common onInit logic
    }
    return SuperRequired.ON_INIT;
  }

  onSelectedClicked(event) {
    if (event.target.classList.contains('ng-option-selected')) {
      this.filter.patchValue(this.filter.value);
    }
  }

  setSelectedFilterState(selectedFilter: string) {
    this.filteringService.setSelectedFilterState(selectedFilter);
  }

  dotMenuConfig(filter: any): void {
    const selectedFilter = this.filter.value ? this.filter.value.filter : null;
    const isEmptyFilter = Object.keys(filter).length === 0;
    if (!isEmptyFilter) {
      if (selectedFilter) {
        if (encodeBase64(filter) === selectedFilter) {
          this.menuConfig.next([this.saveAsMenuConfig, this.deleteMenuConfig]);
        } else if (encodeBase64(filter) !== selectedFilter) {
          this.menuConfig.next([this.saveMenuConfig, this.saveAsMenuConfig, this.deleteMenuConfig]);
        }
      } else {
        this.menuConfig.next([this.saveAsMenuConfig]);
      }
    } else {
      this.menuConfig.next([]);
    }
  }

  getSavedFilters(filterType: ApiTypeParams): void {
    this.userApiService
      .getUsersFilters(filterType)
      .pipe(
        tap((value) => {
          this.filteringManageService.setState({ savedFilters: value });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  openSaveFilterModal(): Observable<any> {
    return this.menuService.eventBus.on(filterActionTypes.manageFilter).pipe(
      tap((isEditMode: boolean) => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: true,
          cancelButtonText: 'common.dismiss',
          approveButtonText: 'common.save',
          modalTitle: isEditMode ? 'common.filter_save' : 'common.filter_save_as',
          iconUrl: 'assets/image/building.svg',
          isSaveButtonDisabled: true,
          approveButtonStyle: 'basic',
          modalText: 'partners.message_delete_confirm',
          isEditMode,
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: FilterModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
      })
    );
  }

  openDeleteFilterModal(): Observable<any> {
    return this.menuService.eventBus.on(filterActionTypes.deleteFilter).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.delete_confirm',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          confirmModalId: '',
          modalText: 'partners.message_delete_confirm',
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: ConfirmModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
      }),
      switchMap(() =>
        this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
          map(() => {
            console.log('confirm button clicked');
          })
        )
      )
    );
  }

  listenFilterValueChange(): void {
    this.filter.valueChanges
      .pipe(
        map((values: GetUsersFilter) => {
          if (values) {
            this.setSelectedFilterState(values.filter);
            this.menuConfig.next([this.saveAsMenuConfig, this.deleteMenuConfig]);
          }
          this.filteringManageService.setState({ filter: values });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  listenConfirmDeleteFilterModal(): void {
    this.openDeleteFilterModal()
      .pipe(
        tap(() => {
          this.filteringManageService.setFilterManageState({ id: this.filter.value.id });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private listenFilterOptionsChange(): void {
    this.savedFilters$
      .pipe(
        map((filtersOptions) => {
          if (this.filter.value) {
            const updatedFilterOption = filtersOptions.filter(
              (filtersOption) => filtersOption.id === this.filter.value.id
            );
            if (updatedFilterOption.length > 0 && this.filter.value !== updatedFilterOption[0]) {
              this.filter.patchValue({ ...updatedFilterOption[0] }, { emitEvent: false });
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private listenSavedFilterChange(): void {
    this.filteringManageService
      .select('savedFilters')
      .pipe(
        tap((savedFilters: GetUsersFilter[]) => {
          this.savedFilters$.next(savedFilters);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  listenDeleteFilterAction(): void {
    this.filteringManageService.deleteFilter().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(skip?: boolean): SuperRequired.ON_DESTROY {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.ON_DESTROY;
  }
}
