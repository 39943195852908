import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  Optional,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IMatModal } from '@shared/modules/mat-modal/classes/IMatModal';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';

@Component({
  selector: 'app-mat-modal-base',
  template: '<ng-content></ng-content>',
})
export class MatModalBaseComponent implements IMatModal {
  modalConfig: MatDialogConfig = {};
  modalStateService: ModalStateService;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public cfr: ComponentFactoryResolver,
    @Optional() public cdr: ChangeDetectorRef,
    @Optional() public injector: Injector
  ) {
    this.modalStateService = injector.get<ModalStateService>(ModalStateService);
  }

  closeModal(data?: any): void {
    this.dialogRef.close(data);
  }
}
