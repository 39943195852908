import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { getAddressString } from '@pages/partners/utils/get-address-string.util';
import { KeyValue } from '@shared/classes/KeyValue';

export function getPartnerKeyValueData(detail: PartnerDetail): KeyValue<string>[] {
  return [
    {
      key: 'partners.company_name',
      value: detail.name,
    },
    {
      key: 'partners.site',
      value: getAddressString(detail.address),
    },
    {
      key: 'common.tax_number',
      value: detail.taxNumber,
    },
    {
      key: 'partners.whc_office',
      value: detail.office.name,
    },
  ];
}
