import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { keys } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppInjector } from '@app/app.injector.service';
import { BaseComponentMessages } from '../../classes/baseComponentMessages';
import { DeviceService } from '../../services/device.service';
import { ButtonClass } from '../form-components/button/ButtonClass';

enum SuperRequired {
  ON_INIT = 'OnInit',
  ON_DESTROY = 'OnDestroy',
}

@UntilDestroy()
@Component({
  template: '',
})
export class BaseComponent implements OnInit, OnDestroy {
  BUTTON_CLASS = ButtonClass;
  protected deviceService: DeviceService;
  protected translate: TranslateService;

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  public isBrowserSupported: boolean;

  // example of usage can be found in BaseComponentMessages interface file
  protected messages: BaseComponentMessages;

  constructor() {
    const injector = AppInjector.getInjector();
    this.deviceService = injector.get(DeviceService);
    this.translate = injector.get(TranslateService);

    this.isMobile = this.deviceService.isMobile;
    this.isTablet = this.deviceService.isTablet;
    this.isDesktop = this.deviceService.isDesktop;
    this.isBrowserSupported = this.deviceService.isBrowserSupported();
  }

  /**
   * Azért lett ilyen fura megoldás, mivel az angular sajnos nem támogatja alapból,
   * hogy szóljon neked az IDE, hogy nem hívtad meg az ős osztály lifecycle hook-ját.
   * Emiatt a megoldás az lett, hogy visszatérési értékként egy az ősosztályban létező
   * enumot vár a függvény. Így, ha ebből származtatsz egy komponenst, és meghívod
   * benne valamelyik lifecycle hook-ot, errort fogsz kapni, mivel nem adja vissza
   * az elvárt értéket. Így ki tudjuk kényszeríteni az ős lifecycle hookjának hívását.
   * @param skip
   * Opcionális, de közös dologkat ebben az ágban lehet majd lekezelni.
   */
  ngOnInit(skip?: boolean): SuperRequired.ON_INIT {
    if (!skip) {
      // common onInit logic
      this.initTranslateMessages();
    }
    return SuperRequired.ON_INIT;
  }

  private initTranslateMessages() {
    this.translateMessages();
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.translateMessages();
    });
  }

  private translateMessages() {
    keys(this.messages).forEach((key) => {
      if (key in this.messages) {
        this.translate
          .get(key, this.messages[key])
          .pipe(untilDestroyed(this))
          .subscribe((translated) => {
            this.messages[key] = translated;
          });
      }
    });
  }

  ngOnDestroy(skip?: boolean): SuperRequired.ON_DESTROY {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.ON_DESTROY;
  }
}
