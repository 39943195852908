import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { SideFilterService } from '@shared/modules/side-filter/services/side-filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { getTranslatePathByPageType } from '@shared/modules/header/utils/header-translate-helper.util';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { TranslationParam } from '@shared/classes/TranslationParam';
import headerActions from '@shared/modules/header/actions/header.actions';
import { AppStateService } from '@shared/services/app-state.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';

@UntilDestroy()
@Component({
  selector: 'app-main-page-header',
  templateUrl: './main-page-header.component.html',
  styleUrls: ['./main-page-header.component.scss'],
})
export class MainPageHeaderComponent extends BaseComponent implements OnInit {
  isSideFilterOpen: boolean;
  filteredResultCount$: Observable<number>;
  headerTitle$: Observable<string>;
  headerButtonParams$: Observable<TranslationParam>;
  createPartnerPermission = UserPermission.CreatePartner;

  constructor(
    private headerService: HeaderService,
    private appService: AppStateService,
    private sideFilterService: SideFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.headerButtonParams$ = this.getHeaderButtonParams();
    this.filteredResultCount$ = this.getFilteredResultCount();
    this.headerTitle$ = this.getHeaderTitle();
    this.listenSideFilterToggleChanges();

    return super.ngOnInit();
  }

  onSideFilterToggle(): void {
    this.sideFilterService.toggleSideFilter(!this.isSideFilterOpen);
  }

  onButtonClick(): void {
    this.headerService.eventBus.dispatch(headerActions.newEntityButtonClickAction());
  }

  private getHeaderTitle(): Observable<string> {
    return this.headerService.select('title');
  }

  private listenSideFilterToggleChanges(): void {
    this.sideFilterService
      .select('isOpen')
      .pipe(
        tap((isOpen) => (this.isSideFilterOpen = isOpen)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getFilteredResultCount(): Observable<number> {
    return this.headerService.select('filterCount');
  }

  private getHeaderButtonParams(): Observable<TranslationParam> {
    return this.appService.select('currentUrl').pipe(
      map((url: string) => ({
        entity: getTranslatePathByPageType(url),
      }))
    );
  }
}
