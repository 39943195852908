import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

@Directive({
  selector: '[appInjectHeader]',
})
export class InjectHeaderDirective implements OnDestroy {
  @Input()
  set appInjectHeader(headerComponent: ComponentType<any>) {
    this.injectHeaderComponent(headerComponent);
  }

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnDestroy(): void {
    this.clearView();
  }

  private injectHeaderComponent(headerComponent: ComponentType<any>): void {
    this.clearView();
    if (headerComponent) {
      const component = this.componentFactoryResolver.resolveComponentFactory(headerComponent);
      this.viewContainer.createComponent(component);
    }
  }

  private clearView(): void {
    this.viewContainer.clear();
  }
}
