import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-table-cell',
  templateUrl: './checkbox-table-cell.component.html',
  styleUrls: ['./checkbox-table-cell.component.scss'],
})
export class CheckboxTableCellComponent implements ICustomCellComponent {
  checkBox = new FormControl();
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<any>;
}
