import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalLoaderService, LoaderState } from './services/global-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
})
export class GlobalLoaderComponent implements OnInit {
  public showGlobalLoader: boolean;

  constructor(private loaderService: GlobalLoaderService, private renderer: Renderer2) {}

  ngOnInit() {
    this.loaderService.loaderState.pipe(untilDestroyed(this)).subscribe((state: LoaderState) => {
      this.showGlobalLoader = state.show;

      this.toggleScrollBlockToHtml();
    });
  }

  private toggleScrollBlockToHtml() {
    if (this.showGlobalLoader) {
      this.renderer.addClass(document.body, 'cdk-global-scrollblock');
    } else {
      this.renderer.removeClass(document.body, 'cdk-global-scrollblock');
    }
  }
}
