<span class="d-flex align-items-center">
    <app-primary-button
      class="action-button mr-10px"
      buttonStyle="outline"
      [text]="secondaryButtonText | translate"
      (buttonClick)="onSecondaryClicked()">
    </app-primary-button>
    <app-primary-button
      class="action-button"
      [text]="primaryButtonText | translate"
      [disabled]="primaryButtonDisabled"
      [color]="buttonColor$ | async"
      (buttonClick)="onPrimaryClicked()">
    </app-primary-button>
</span>
