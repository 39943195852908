import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { ProjectDetail } from '@pages/partners/classes/ProjectDetail';
import { ProjectCardTableRow } from '@pages/partners/classes/ProjectCardTableRow';
import { PartnerBulkUpdate } from '@pages/partners/classes/PartnerBulkUpdate';

export interface PartnerState {
  partnerDetail: PartnerDetail;
  projectDetail: ProjectDetail;
  projects: ProjectDetail[];
  isPartnerDetailLoaded: boolean;
  isProjectDetailLoaded: boolean;
  historyMessages: HistoryMessage[];
  allMessagesCount: number;
  selectedProject: ProjectCardTableRow;
  currentProjectPage: number;
  currentHistoryPage: number;
  partnerFilters: {
    name: string;
    letter_filter: string;
    tax_number: string;
    field_manager_id: number[];
    office_id: number[];
    city_id: number[];
    sortBy: {}[];
  };
  firstLetters: string[];
  allProjectCount: number;
  headerText: string;
  partnerBulkUpdate: PartnerBulkUpdate;
}

export const partnerInitialState: PartnerState = {
  partnerDetail: undefined,
  projectDetail: undefined,
  projects: [],
  isPartnerDetailLoaded: false,
  isProjectDetailLoaded: false,
  historyMessages: [],
  allMessagesCount: 0,
  selectedProject: undefined,
  currentHistoryPage: 1,
  currentProjectPage: 1,
  partnerFilters: {
    name: '',
    letter_filter: '',
    tax_number: '',
    field_manager_id: [],
    office_id: [],
    city_id: [],
    sortBy: [],
  },
  firstLetters: [],
  allProjectCount: 0,
  headerText: '',
  partnerBulkUpdate: undefined,
};
